<template>
    <div class="articleList" :class="{ mobile: $store.state.isMobile }">
      <!-- Existing content -->
  
      <!-- Footer Section -->
      <footer class="footer is-dark">
        <div class="content has-text-centered">
          <p class="title is-3 footerContent">Data-Driven, AI-Enhanced Travel Content, Backed by In-Depth Analysis </p>
          <p class="subtitle is-6 footerContent">Unlock expert-curated travel insights, powered by advanced AI and backed by in-depth research. </p>
          <p>Make informed decisions, discover hidden gems, and plan your perfect trip with confidence.</p>
          <!-- Random Social Media Links -->
          <div class="social-icons">
            <a href="https://facebook.com" target="_blank" class="icon">
              <i class="fab fa-facebook"></i>
            </a>
            <a href="https://twitter.com" target="_blank" class="icon">
              <i class="fab fa-twitter"></i>
            </a>
            <a href="https://instagram.com" target="_blank" class="icon">
              <i class="fab fa-instagram"></i>
            </a>
          </div>
  
          <!-- Random Navigation Links -->
          <nav class="footer-nav">
            <a href="/aboutus/index.html">About Us</a> |
            <!-- <a href="/privacy-policy">Privacy Policy</a> | -->
            <a href="/contact">Contact Us</a> | 
            <a href="/sitemap">Sitemap</a> 
            <!-- | -->
            <!-- <a href="/faq">FAQ</a> -->
          </nav>
  
          <!-- Random Quote -->
          <p class="random-quote">"The world is a book, and those who do not travel read only one page." - Augustine of Hippo</p>
         
          <!-- Copyright -->
          <p>&copy; 2024 Travel Enthusiasts. All rights reserved.</p>
        </div>
      </footer>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        // Optionally add logic for dynamic content
      };
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .footer {
    /* width:93%; */
    /* margin-left:7%; */
    padding: 2rem 1.5rem 2rem 7rem;
    background-color: #fff;
   
  }
  .footerContent{
    color:black;
  }
  .social-icons .icon {
    margin: 0 0.5rem;
    font-size: 1.5rem;
    color: #fff;
  }
  
  .footer-nav {
    margin: 1rem 0;
  }
  
  .footer-nav a {
    color: #ccc;
    margin: 0 0.5rem;
  
  }
  
  .random-quote {
    font-style: italic;
    margin: 1.5rem 0;
  }
  
  .footer-nav a:hover {
    text-decoration: underline;
  }

  .mobile{ 
    .footer{
      padding: 2rem 1.5rem 2rem 1.5rem;
      background-color: #fff;
    }
  }
  </style>
  
