let foodList = [
  {
    type: "street-food",
    name: "streetFood",
    path: "travel-street-food",
    title: "Travel Food street-food",
    keywordId: "434",
    keywordName: "Street Food"
  },
  {
    type: "local-dishes",
    name: "localDishes",
    path: "travel-local-dishes",
    title: "Travel Food local-dishes",
    keywordId: "434",
    keywordName: "Local Dishes"
  },
  {
    type: "food-markets",
    name: "foodMarkets",
    path: "travel-food-markets",
    title: "Travel Food food-markets",
    keywordId: "434",
    keywordName: "Food Markets"
  },
  {
    type: "food-chains",
    name: "foodChains",
    path: "travel-food-chains",
    title: "Travel Food food-chains",
    keywordId: "434",
    keywordName: "Food chains"
  },
  {
    type: "regional-specialties",
    name: "regionalSpecialties",
    path: "travel-regional-specialties",
    title: "Travel Food regional-specialties",
    keywordId: "434",
    keywordName: "Regional Specialties"
  },
  {
    type: "fine-dining",
    name: "fineDining",
    path: "travel-fine-dining",
    title: "Travel Food fine-dining",
    keywordId: "434",
    keywordName: "Fine Dining"
  },
  {
    type: "quick-bites",
    name: "quickBites",
    path: "travel-quick-bites",
    title: "Travel Food quick-bites",
    keywordId: "434",
    keywordName: "Quick Bites"
  }
]

let foodTypeRouteList = foodList.map(item => {
  return {
      path: `/${item.path}/:name?`,
      name: item.name,
      component: () => import(`@/views/thingToDo/country/food.vue`),
      meta: {
          tab: "Hot",
          title: item.title,
          type: item.type,
          keywordId: item.keywordId,
          keywordName: item.keywordName
      }
  }
})

export default foodTypeRouteList