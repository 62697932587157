let luxuryList = [
  {
    type: "travel-luxury-resorts-hotels",
    name: "travelLuxuryResortsHotels",
    path: "travel-luxury-resorts-hotels",
    title: "travel luxury resorts & hotels",
    keywordId: "419",
    keywordName: "luxury resorts & hotels"
  },
  {
    type: "travel-fine-dining-Michelin-restaurants",
    name: "travelFineDiningMichelinRestaurants",
    path: "travel-fine-dining-Michelin-restaurants",
    title: "travel fine dining & Michelin restaurants",
    keywordId: "357",
    keywordName: "fine dining & Michelin restaurants"
  },

  {
    type: "travel-private-tours-custom-experiences",
    name: "travelPrivateToursCustomExperiences",
    path: "travel-private-tours-custom-experiences",
    title: "travel private tours & custom experiences",
    keywordId: "418",
    keywordName: "private tours & custom experiences"
  },
  {
    type: "travel-spa-wellness-retreats",
    name: "travelSpaWellnessRetreats",
    path: "travel-spa-wellness-retreats",
    title: "travel spa & wellness retreats",
    keywordId: "418",
    keywordName: "spa & wellness retreats"
  },
  {
    type: "travel-private-jets-helicopter-tours",
    name: "travelPrivateJetsHelicopterTours",
    path: "travel-private-jets-helicopter-tours",
    title: "travel private jets & helicopter tours",
    keywordId: "418",
    keywordName: "private jets & helicopter tours"
  }, 

  {
    type: "travel-luxury-shopping-designer-boutiques",
    name: "travelLuxuryShoppingDesignerBoutiques",
    path: "travel-luxury-shopping-designer-boutiques",
    title: "travel luxury shopping & designer boutiques",
    keywordId: "418",
    keywordName: "luxury shopping & designer boutiques"
  },
  {
    type: "travel-nature-reserves",
    name: "travelNatureReserves",
    path: "travel-nature-reserves",
    title: "travel nature reserves",
    keywordId: "418",
    keywordName: "nature reserves"
  },
  {
    type: "travel-private-yacht-charters",
    name: "travelPrivateYachtCharters",
    path: "travel-private-yacht-charters",
    title: "travel private yacht charters",
    keywordId: "418",
    keywordName: "private yacht charters"
  },

  {
    type: "travel-cultural-arts-experiences",
    name: "travelCulturalArtsExperiences",
    path: "travel-cultural-arts-experiences",
    title: "travel cultural & arts experiences",
    keywordId: "418",
    keywordName: "cultural & arts experiences"
  },

  {
    type: "travel-gourmet-wine-tasting-experiences",
    name: "travelGourmetWineTastingExperiences",
    path: "travel-gourmet-wine-tasting-experiences",
    title: "travel gourmet & wine tasting experiences",
    keywordId: "418",
    keywordName: "gourmet & wine tasting experiences"
  },
  {
    type: "travel-exclusive-events-private-parties",
    name: "travelExclusiveEventsPrivateParties",
    path: "travel-exclusive-events-private-parties",
    title: "travel exclusive events & private parties",
    keywordId: "418",
    keywordName: "exclusive events & private parties"
  }
]

let luxuryRouteList = luxuryList.map(item => {
  return {
      path: `/${item.path}/:name?`,
      name: item.name,
      component: () => import(`@/views/thingToDo/country/luxury.vue`),
      meta: {
          tab: "Hot",
          title: item.title,
          type: item.type,
          keywordId: item.keywordId,
          keywordName: item.keywordName
      }
  }
})

export default luxuryRouteList