let activitiesList = [
  {
    type: "travel-adventure-packed-hikes",
    name: "travelAdventurePackedHikes",
    path: "travel-adventure-packed-hikes",
    title: "travel adventure-packed hikes",
    keywordId: "419",
    keywordName: "adventure-packed hikes"
  },
  {
    type: "travel-water-sports",
    name: "travelWaterSports",
    path: "travel-water-sports",
    title: "travel water sports(scuba diving)",
    keywordId: "357",
    keywordName: "water sports(scuba diving)"
  },

  {
    type: "travel-thrilling-outdoor-exploration",
    name: "travelThrillingOutdoorExploration",
    path: "travel-thrilling-outdoor-exploration",
    title: "travel thrilling outdoor exploration",
    keywordId: "418",
    keywordName: "thrilling outdoor exploration"
  },
  {
    type: "travel-relaxing-spa-treatments",
    name: "travelRelaxingSpaTreatments",
    path: "travel-relaxing-spa-treatments",
    title: "travel relaxing spa treatments",
    keywordId: "418",
    keywordName: "relaxing spa treatments"
  },
  {
    type: "travel-interactive-museums",
    name: "travelInteractiveMuseums",
    path: "travel-interactive-museums",
    title: "travel interactive museums",
    keywordId: "418",
    keywordName: "interactive museums"
  }, 

  {
    type: "travel-amusement-parks",
    name: "travelAmusementParks",
    path: "travel-amusement-parks",
    title: "travel amusement parks",
    keywordId: "418",
    keywordName: "amusement parks"
  },
  {
    type: "travel-nature-walks",
    name: "travelNatureWalks",
    path: "travel-nature-walks",
    title: "travel nature walks",
    keywordId: "418",
    keywordName: "nature walks"
  },
  {
    type: "travel-city-walks",
    name: "travelCityWalks",
    path: "travel-city-walks",
    title: "travel city walks",
    keywordId: "418",
    keywordName: "city walks"
  },

  {
    type: "travel-romantic-getaways",
    name: "travelRomanticGetaways",
    path: "travel-romantic-getaways",
    title: "travel romantic getaways",
    keywordId: "418",
    keywordName: "romantic getaways"
  },

  {
    type: "travel-sunset-cruises",
    name: "travelSunsetCruises",
    path: "travel-sunset-cruises",
    title: "travel sunset cruises",
    keywordId: "418",
    keywordName: "sunset cruises"
  },
  {
    type: "travel-private-dinners",
    name: "travelPrivateDinners",
    path: "travel-private-dinners",
    title: "travel private dinners",
    keywordId: "418",
    keywordName: "private dinners"
  },
  {
    type: "travel-couples-spa-retreats",
    name: "travelCouplesSpaRetreats",
    path: "travel-couples-spa-retreats",
    title: "travel couples' spa retreats",
    keywordId: "418",
    keywordName: "couples' spa retreats"
  },
  {
    type: "travel-cultural-tours",
    name: "travelCulturalTours",
    path: "travel-cultural-tours",
    title: "travel cultural tours",
    keywordId: "418",
    keywordName: "cultural tours"
  },
  {
    type: "travel-wildlife-safaris",
    name: "travelWildlifeSafaris",
    path: "travel-wildlife-safaris",
    title: "travel wildlife safaris",
    keywordId: "418",
    keywordName: "wildlife safaris"
  },
  {
    type: "travel-eco-tourism-adventures",
    name: "travelEcoTourismAdventures",
    path: "travel-eco-tourism-adventures",
    title: "travel eco-tourism adventures",
    keywordId: "418",
    keywordName: "eco-tourism adventures"
  },
  {
    type: "travel-mountain-biking",
    name: "travelMountainBiking",
    path: "travel-mountain-biking",
    title: "travel mountain biking",
    keywordId: "418",
    keywordName: "mountain biking"
  },
  {
    type: "travel-snorkeling",
    name: "travelSnorkeling",
    path: "travel-snorkeling",
    title: "travel snorkeling",
    keywordId: "418",
    keywordName: "snorkeling"
  },
  {
    type: "travel-cooking-classes",
    name: "travelCookingClasses",
    path: "travel-cooking-classes",
    title: "travel cooking classes",
    keywordId: "418",
    keywordName: "cooking classes"
  }
]

let activitiesRouteList = activitiesList.map(item => {
  return {
      path: `/${item.path}/:name?`,
      name: item.name,
      component: () => import(`@/views/thingToDo/country/activities.vue`),
      meta: {
          tab: "Hot",
          title: item.title,
          type: item.type,
          keywordId: item.keywordId,
          keywordName: item.keywordName
      }
  }
})

export default activitiesRouteList