let natureList = [
  {
    type: "travel-mountains-hiking",
    name: "travelMountainsHiking",
    path: "travel-mountains-hiking",
    title: "travel mountains hiking",
    keywordId: "428",
    keywordName: "mountains hiking"
  },
  {
    type: "travel-lakes-rivers",
    name: "travelLakesRivers",
    path: "travel-lakes-rivers",
    title: "travel lakes & rivers(Golf)",
    keywordId: "430",
    keywordName: "lakes & rivers(Golf)"
  },

  {
    type: "travel-national-parks",
    name: "travelNationalParks",
    path: "travel-national-parks",
    title: "travel national parks",
    keywordId: "428",
    keywordName: "national parks"
  },

  {
    type: "travel-wildlife-animal-watching",
    name: "travelWildlifeAnimalWatching",
    path: "travel-wildlife-animal-watching",
    title: "travel wildlife & animal watching",
    keywordId: "428",
    keywordName: "wildlife & animal watching"
  },

  {
    type: "travel-beaches-coastal-areas",
    name: "travelBeachesCoastalAreas",
    path: "travel-beaches-coastal-areas",
    title: "travel beaches （volleyball, diving） & coastal areas（surfing,sailing）",
    keywordId: "428",
    keywordName: "beaches （volleyball, diving） & coastal areas（surfing,sailing）"
  },

  {
    type: "travel-local-customs",
    name: "travelLocalCustoms",
    path: "travel-local-customs",
    title: "travel local customs",
    keywordId: "428",
    keywordName: "Local Customs"
  },

  {
    type: "travel-forests-woodlands",
    name: "travelForestsWoodlands",
    path: "travel-forests-woodlands",
    title: "travel forests woodlands",
    keywordId: "428",
    keywordName: "forests woodlands"
  },

  {
    type: "travel-caves-geological-wonders",
    name: "travelCavesGeologicalWonders",
    path: "travel-caves-geological-wonders",
    title: "travel caves & geological wonders",
    keywordId: "428",
    keywordName: "caves & geological wonders"
  },
  {
    type: "travel-deserts-unique-landscapes",
    name: "travelDesertsUniqueLandscapes",
    path: "travel-deserts-unique-landscapes",
    title: "travel deserts & unique landscapes",
    keywordId: "428",
    keywordName: "deserts & unique landscapes"
  },
  {
    type: "travel-eco-tourism-sustainable-travel",
    name: "travelEcoTourismSustainableTravel",
    path: "travel-eco-tourism-sustainable-travel",
    title: "travel eco-tourism & sustainable travel",
    keywordId: "428",
    keywordName: "eco-tourism & sustainable travel"
  },
  {
    type: "travel-botanical-gardens-nature-reserves",
    name: "travelBotanicalGardensNatureReserves",
    path: "travel-botanical-gardens-nature-reserves",
    title: "travel botanical gardens & nature reserves",
    keywordId: "428",
    keywordName: "botanical gardens & nature reserves"
  }
]

let natureRouteList = natureList.map(item => {
  return {
    path: `/${item.path}/:name?`,
    name: item.name,
    component: () => import(`@/views/thingToDo/country/nature.vue`),
    meta: {
      tab: "Hot",
      title: item.title,
      type: item.type,
      keywordId: item.keywordId,
      keywordName: item.keywordName
    }
  }
})

export default natureRouteList