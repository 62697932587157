<template>
  <div class="library">
    <div class="libraryView">
      <SubSearch></SubSearch>
      <Tags></Tags>
      <!-- <UserData></UserData> -->
      <SearchResult></SearchResult>
    </div>
  </div>
</template>

<script>
import SubSearch from "./components/subSearch/index.vue";
import Tags from "./components/tags/index.vue";
// import UserData from "./components/userData/index.vue";
import SearchResult from "./components/searchResult/index.vue";
export default {
  components: {
    SubSearch,
    Tags,
    // UserData,
    SearchResult,
  },
  beforeCreate() {
    if (sessionStorage.getItem("discover")) {
      this.$store.state.discover.state = JSON.parse(
        sessionStorage.getItem("discover")
      );
    }
  },
  created() {
    document.title = 'discover, relax and enjoy asia with leisuretripinasia.com'
    this.changeMeta();
  },
  beforeDestroy() {
    sessionStorage.removeItem("discover");
  },
  methods: {
    changeMeta() {
      
      const { countryName, cityName, triptypeName } = this.$route.params;

      let descriptionEl = document.getElementById("description");

      descriptionEl.setAttribute(
        "content",
        "leisuretripinasia.com best help travellers discover " +
          (cityName ? cityName + " city of " : "") +
          (countryName ? countryName + "'s" : "") +
          " top travel experience " +
          (triptypeName ? " in " + triptypeName : "")
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.library {
  .libraryView {
    // padding: 10px;
    // min-height: 190vh;
    // min-width: 100%;
    // width: 1200px;
    // width: 90vw;
    width: calc(100% - 90px);
    // width: calc(100% - 100px);
    min-width: 1100px;
    margin: 0 auto;
    transform: translateX(32px);
  }
}
</style>