import { replaceSpacesWithDash } from "@/utils/utils";
export default {
  methods: {
    // 国家详情界面
    openCountry(val, flag) {
      let path = `/travel-country/` + replaceSpacesWithDash(val.countryName);
      let query = {
        countryId: val.countryId,
      };
      let routeData = this.$router.resolve({
        path: path, // path 要跳转的路由地址
        query: query,
      });
      if (flag) {
        window.open(routeData.href, "_blank");
      }
      return routeData.href;
    },

    // 城市详情界面
    openCity(val) {
      let path = `/travel-city/${replaceSpacesWithDash(val.countryName)}/${replaceSpacesWithDash(val.cityName)}`
      let query = {
        countryId: val.countryId,
        cityId: val.cityId,
      };
      let routeData = this.$router.resolve({
        path: path, // path 要跳转的路由地址
        query: query,
      });
      return routeData.href;
    },

    // 国家城市界面
    openCountryCites(val) {
      let path = `/travel-faq/${replaceSpacesWithDash(val.countryName)}/cities`
      if (val.type == 'news') {
        path = `/travel-destination-news/${replaceSpacesWithDash(val.countryName)}/cities`
      }
      let query = {
        countryId: val.countryId,
        countryName: val.countryName
      };
      let routeData = this.$router.resolve({
        path: path, // path 要跳转的路由地址
        query: query,
      });
      return routeData.href;
    },

    // 国家新闻界面
    openCountryNews(val) {
      let path = `/travel-destination-news/${replaceSpacesWithDash(val.countryName)}/news`
      let query = {
        countryId: val.countryId,
        countryName: val.countryName
      };
      let routeData = this.$router.resolve({
        path: path, // path 要跳转的路由地址
        query: query,
      });
      return routeData.href;
    },

    // 城市新闻界面
    openCityNews(val, flag) {
      let path = `/travel-destination-news/${replaceSpacesWithDash(val.countryName)}/${replaceSpacesWithDash(val.cityName)}/news`;
      let query = {
        countryId: val.countryId,
        countryName: val.countryName,
        cityId: val.cityId,
        cityName: val.cityName
      };
      let routeData = this.$router.resolve({
        path: path, // path 要跳转的路由地址
        query: query,
      });
      if (flag) {
        window.open(routeData.href, "_blank");
      }
      return routeData.href;
    },

    // 国家FAQ
    openCountryFaq(val) {
      let path = `/travel-faq/${replaceSpacesWithDash(val.countryName)}/question`
      let query = {
        countryId: val.countryId,
        countryName: val.countryName
      };
      let routeData = this.$router.resolve({
        path: path, // path 要跳转的路由地址
        query: query,
      });
      return routeData.href;
    },
    // 城市FAQ
    openCityFaq(val) {
      let path = `/travel-faq/${replaceSpacesWithDash(val.countryName)}/${replaceSpacesWithDash(val.cityName)}/question`
      let query = {
        countryId: val.countryId,
        countryName: val.countryName,
        cityId: val.cityId,
        cityName: val.cityName
      };
      let routeData = this.$router.resolve({
        path: path, // path 要跳转的路由地址
        query: query,
      });
      return routeData.href;
    },

    // 景点详情页
    openScene(val, flag) {
      let path = `/travel-attractions/${replaceSpacesWithDash(val.country.name)}/${replaceSpacesWithDash(val.enName)}`
      let query = {
        countryId: val.country.id,
        sceneId: val.id
      }
      let routeData = this.$router.resolve(({
        path: path, // path 要跳转的路由地址
        query: query
      }))
      if (flag) {
        window.open(routeData.href, "_blank");
      }
      return routeData.href
    },

    // 文章详情页 链接
    handleArticleLink(item) {
      let isHotWord = 'travel'
      if (item.isHot) {
        isHotWord = 'hot'
      }
      let typeWord = ''
      if (item.articleTypeList && item.articleTypeList.length) {
        let articleType = item.articleTypeList[0]
        switch (articleType.id) {
          case 330:
            typeWord = 'guide'
            break
          case 388:
            typeWord = 'reviews'
            break
          case 387:
            typeWord = 'advice'
            break
          case 385:
            typeWord = 'topic'
            break
          case 332:
            typeWord = 'news'
            break
          default:
            isHotWord = 'hot'
            typeWord = 'travel-faq-detail'
        }
      } else {
        isHotWord = 'hot'
        typeWord = 'travel-faq-detail'
      }
      let path = `/${isHotWord}-${typeWord}/${replaceSpacesWithDash(item.headline)}`
      if (item.countryName) {
        path = `/${isHotWord}-${typeWord}/${item.countryName}/${replaceSpacesWithDash(item.headline)}`
      }
      let query = {
        id: item.id,
        img: encodeURIComponent(item.source.mediaLink)
      }
      let routeData = this.$router.resolve(({
        path: path,
        query: query
      }))
      return routeData.href;
    },

    // 国家 Thing to do 
    openThingToDo(val) {
      let path = `/things-to-do/${replaceSpacesWithDash(val.countryName)}`;
      let query = {
        countryId: val.countryId
      };
      let routeData = this.$router.resolve({
        path: path, // path 要跳转的路由地址
        query: query,
      });
      return routeData.href
    },


    // Things To Do 二级界面

    goPath(val, urlPath) {
      let path = `/${urlPath}/${replaceSpacesWithDash(val.countryName)}`;
      let query = {
        countryId: val.countryId,
        countryName: val.countryName
      }
      let routeData = this.$router.resolve({
        path: path, // path 要跳转的路由地址
        query: query,
      });
      return routeData.href
    },

    // hot food 详情
    openHotFood(item) {
      let path = `/hot-food/${replaceSpacesWithDash(item.countryName)}/${replaceSpacesWithDash(item.headline)}`;
      let query = {
        id: item.id,
        img: encodeURIComponent(item.source.mediaLink)
      };
      let routeData = this.$router.resolve({
        path: path, // path 要跳转的路由地址
        query: query,
      });
      return routeData.href
    }

  }
}