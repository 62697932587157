let basicsList = [
  {
    type: "travel-language",
    name: "travelLanguage",
    path: "travel-language",
    title: "travel language",
    keywordId: "419",
    keywordName: "Language"
  },
  {
    type: "travel-weather",
    name: "travelWeather",
    path: "travel-weather",
    title: "travel weather",
    keywordId: "357",
    keywordName: "weather"
  },

  {
    type: "travel-history",
    name: "travelHistory",
    path: "travel-history",
    title: "travel history",
    keywordId: "418",
    keywordName: "history"
  }, 
]

let basicsRouteList = basicsList.map(item => {
  return {
      path: `/${item.path}/:name?`,
      name: item.name,
      component: () => import(`@/views/thingToDo/country/basics.vue`),
      meta: {
          tab: "Hot",
          title: item.title,
          type: item.type,
          keywordId: item.keywordId,
          keywordName: item.keywordName
      }
  }
})

export default basicsRouteList