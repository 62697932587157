/*
 * @Author: r_yanxu r_yanxu@ctx.com.sg
 * @LastEditors: r_yanxu r_yanxu@ctx.com.sg
 * @Date: 2024-12-26 10:45:12
 * @Description: 
 */
// index.js,我放在根目录下/store/index.js
import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

import discover from './discover.js'
import user from './user.js'

export default new Vuex.Store({
  state: {
    isMobile: false,
    allCountryList: [
      {
        key: "*",
        list: [
          {
            name: "China",
            id: "285",
          },
          {
            name: "Indonesia",
            id: "18",
          },
          {
            name: "Japan",
            id: "305",
          },
          {
            name: "Korea",
            id: "15",
          },
          {
            name: "Thailand",
            id: "14",
          },
          {
            name: "Vietnam",
            id: "16",
          },
        ],
      },
      {
        key: "A",
        list: [
          {
            name: "Afghanistan",
            id: "294",
          },
          {
            name: "Armenia",
            id: "295",
          },
          {
            name: "Azerbaijan",
            id: "296",
          },
        ],
      },
      {
        key: "B",
        list: [
          {
            name: "Bahrain",
            id: "297",
          },
          {
            name: "Bangladesh",
            id: "28",
          },
          {
            name: "Bhutan",
            id: "298",
          },
          {
            name: "Brunei",
            id: "299",
          },
        ],
      },
      {
        key: "C",
        list: [
          {
            name: "Cambodia",
            id: "25",
          },
          {
            name: "China",
            id: "285",
          },
          {
            name: "Cyprus",
            id: "300",
          },
        ],
      },
      {
        key: "E",
        list: [
          {
            name: "East timor",
            id: "301",
          },
        ],
      },
      {
        key: "G",
        list: [
          {
            name: "Georgia",
            id: "302",
          },
        ],
      },
      {
        key: "I",
        list: [
          {
            name: "India",
            id: "18",
          },
          {
            name: "Indonesia",
            id: "19",
          },
          {
            name: "Iran",
            id: "303",
          },
          {
            name: "Iraq",
            id: "304",
          },
          {
            name: "Israel",
            id: "29",
          },
        ],
      },
      {
        key: "J",
        list: [
          {
            name: "Japan",
            id: "33",
          },
          {
            name: "Jordan",
            id: "306",
          },
        ],
      },
      {
        key: "K",
        list: [
          {
            name: "Kazakhstan",
            id: "306",
          },
          {
            name: "Korea",
            id: "15",
          },
          {
            name: "Kuwait",
            id: "307",
          },
          {
            name: "Kyrgyzstan",
            id: "308",
          },
        ],
      },
      {
        key: "L",
        list: [
          {
            name: "Laos",
            id: "24",
          },
          {
            name: "Lebanon",
            id: "309",
          },
        ],
      },
      {
        key: "M",
        list: [
          {
            name: "Malaysia",
            id: "17",
          },
          {
            name: "Maldives",
            id: "30",
          },
          {
            name: "Mongolia",
            id: "310",
          },
          {
            name: "Myanmar",
            id: "27",
          },
        ],
      },
      {
        key: "N",
        list: [
          {
            name: "Nepal",
            id: "23",
          },
          {
            name: "North Korea",
            id: "311",
          },
        ],
      },
      {
        key: "O",
        list: [
          {
            name: "Oman",
            id: "312",
          },
        ],
      },
      {
        key: "P",
        list: [
          {
            name: "Pakistan",
            id: "31",
          },
          {
            name: "Palestine",
            id: "313",
          },
          {
            name: "Philippines",
            id: "22",
          },
        ],
      },
      {
        key: "Q",
        list: [
          {
            name: "Qatar",
            id: "33",
          },
        ],
      },
      {
        key: "S",
        list: [
          {
            name: "Saudi Arabia",
            id: "32",
          },
          {
            name: "Singapore",
            id: "20",
          },
          {
            name: "Sri Lanka",
            id: "26",
          },
          {
            name: "Syria",
            id: "314",
          },
        ],
      },
      {
        key: "T",
        list: [
          {
            name: "Tajikistan",
            id: "315",
          },
          {
            name: "Thailand",
            id: "14",
          },
          {
            name: "Turkey",
            id: "316",
          },
          {
            name: "Turkmenistan",
            id: "317",
          },
        ],
      },
      {
        key: "U",
        list: [
          {
            name: "United Arab Emirates",
            id: "21",
          },
          {
            name: "Uzbekistan",
            id: "318",
          },
        ],
      },
      {
        key: "V",
        list: [
          {
            name: "Vietnam",
            id: "16",
          },
        ],
      },
      {
        key: "Y",
        list: [
          {
            name: "Yemen",
            id: "293",
          },
        ],
      },
    ],
  },
  mutations: {
    setIsMobile(state, data) {
      state.isMobile = data
    }
  },
  modules: {
    discover,
    user
  }
})
