<template>
    <div class="articleItem" :style="{ width: width, margin: margin }">
        <div class="item" v-if="info.id">
            <a class="pic" :href="handleArticleLink(info)" target="_blank">
                <img v-if="info.source" :src="info.source.mediaLink" :alt="'leisuretripinasia.com ' + info.headline"
                    @error="onImageError" />
                <img v-else :src="img" :alt="'leisuretripinasia.com ' + info.headline" @error="onImageError" />
            </a>
            <a class="title" :href="handleArticleLink(info)" target="_blank">
                <h3>{{ info.headline }}</h3>
            </a>

            <div class="bottom">
                <a :href="handleCountryLink(info)" target="_blank">
                    <h2>{{ info.countryName ? info.countryName : '' }}</h2>
                </a>
                <a :href="handleArticleLink(info)" target="_blank">
                    <p class="desc">{{ timestampToEnDateString(info.createTime) }}</p>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import { timestampToDateString, timestampToEnDateString, replaceSpacesWithDash } from '@/utils/utils'
import OpenLinkMixins from "@/mixins/openLink"
export default {
    mixins: [OpenLinkMixins],
    props: {
        info: {
            type: Object,
        },
        width: {
            type: String,
            default: '200px'
        },
        margin: {
            type: String,
            default: '15px 20px 0 0'
        },
    },
    data() {
        return {
            img: require("@/assets/img/default.png")
        }
    },
    methods: {
        timestampToDateString(timestamp, format) {
            return timestampToDateString(timestamp * 1000, format)
        },
        timestampToEnDateString(timestamp) {
            return timestampToEnDateString(timestamp * 1000)
        },
        onImageError(event) {
            event.target.src = require("@/assets/img/default.png");
        },
        //国家跳转链接
        handleCountryLink(item) {
            let path = `/travel-country/` + item.countryName;
            let query = {
                countryId: item.destCountry,
            };
            let routeData = this.$router.resolve({
                path: path, // path 要跳转的路由地址
                query: query,
            });
            return routeData.href;
        },
    },
    //生命周期 - 创建完成（访问当前this实例）
    created() {

    },
    //生命周期 - 挂载完成（访问DOM元素）
    mounted() {

    }
}
</script>
<style lang="scss" scoped>
.articleItem {
    height: 250px;
    // margin: 15px 30px 0 0;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    position: relative;
    border-radius: 4px;
    overflow: hidden;

    .item {
        background: #fff;
        height: 100%;
    }

    a {
        color: #222222 !important;
    }

    .pic {
        width: 100%;
        height: 125px;
        transition: all 0.5s;
        overflow: hidden;
        display: block;

        &:hover {
            img {
                transform: scale(1.2);
            }
        }

        img {
            width: 100%;
            height: 100%;
            display: inline-block;
            object-fit: cover;
            transition: transform 0.3s ease;
        }
    }

    .title {
        display: block;
        box-sizing: border-box;
        padding: 6px;
        transition: color 0.1s ease-in-out;
        flex: 1;

        h3 {
            color: #0c0c0c;
            // font-weight: 600;
            font-size: 14px;
            line-height: 1.4;
        }

        &:hover {
            color: #e65800 !important;
        }
    }

    .bottom {
        display: block;
        position: absolute;
        // bottom: 28px;
        // bottom:.2rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 0 6px;

        h2 {
            font-weight: 600;
            font-size: 12px;
            color: #666;
        }

        .desc {
            // width: 45px;
            height: 12px;
            font-weight: 600;
            line-height: 12px;
            font-size: 12px;
            // background: #222;
            // color: #FFFFFF;
            text-align: center;
        }
    }
}
</style>