/*
 * @Author: r_yanxu r_yanxu@ctx.com.sg
 * @LastEditors: r_yanxu r_yanxu@ctx.com.sg
 * @Date: 2025-01-17 15:02:29
 * @Description: 
 */
export default {
  state: {
    isLoginModeActive: false,
    userInfo: {
      nickName: '',
      token: '',
      id: '',
      email: ''
    }
  },
  getters: {
    userInfo(state) {
      return state.userInfo
    }
  },
  mutations: {
    setLoginModeActive(state, value) {
      state.isLoginModeActive = value
    },
    setUserInfo(state) {
      state.userInfo = {
        nickName: localStorage.getItem('api-nickName') ? localStorage.getItem('api-nickName') : '',
        token: localStorage.getItem('api-token') ? localStorage.getItem('api-token') : '',
        id: localStorage.getItem('api-id') ? localStorage.getItem('api-id') : '',
        email: localStorage.getItem('api-email') ? localStorage.getItem('api-email') : ''
      }
    },
    LogOut(state) {
      state.userInfo = {
        nickName: '',
        token: '',
        id: '',
        email: ''
      }
      localStorage.removeItem("api-nickName");
      localStorage.removeItem("api-token");
      localStorage.removeItem("api-id");
      localStorage.removeItem("api-email");
      localStorage.removeItem("api-loginFrom");
    }
  }
}
