<template>
  <div class="articleForm box">
    <section class="box">
      <div class="level title is-6">
        <div class="level-left is-danger">
          {{ (parentData.id ? "编辑 " : "创建 ") + "文章" }}
        </div>
        <div class="left-item"></div>
      </div>
      <b-field  :label-position="labelPosition" label="文章ID :" horizontal v-if="parentData.id">
        {{ parentData.id }}
      </b-field>
      <b-field  :label-position="labelPosition" label="文章名称:" horizontal>
        <b-input
          v-model="parentData.headline"
          size="is-small"
          :value="parentData.headline"
          required
        ></b-input>
      </b-field>
      <b-field  :label-position="labelPosition" label="推荐上热门:" horizontal>
        <b-radio v-model="isHot" size="is-small" native-value="1" required
          >是</b-radio
        >
        <b-radio v-model="isHot" size="is-small" native-value="0" required
          >否</b-radio
        >
      </b-field>
      <b-field  :label-position="labelPosition" label="文章来源:" horizontal>
        <b-input
          v-model="sourceVo.name"
          size="is-small"
          :value="sourceVo.name"
          required
        ></b-input>
        </b-field>
      <b-field  :label-position="labelPosition" label="文章作者:" horizontal>
        <b-input
          v-model="sourceVo.author"
          size="is-small"
          :value="sourceVo.author"
          required
        ></b-input>
      </b-field>

      <!-- <b-field  :label-position="labelPosition" label="文章类型:" horizontal>
        <b-select
          size="is-small"
          placeholder="选择文章类型"
          v-model="parentData.articleType"
        >
          <option
            v-for="option in articleTypeList"
            :value="option.id"
            :key="option.id"
          >
            {{ option.name }}
          </option>
        </b-select>
      </b-field>  -->
      <b-field  :label-position="labelPosition" label="文章类型:" horizontal>
        <section>
          <b-tooltip
            v-for="(item, index) in articleTypeList"
            :key="index"
            :label-position="labelPosition" :label="item.desc"
            type="is-info is-light"
            multilined
          >
            <b-checkbox
              size="is-small"
              v-model="articleTypeIdList"
              :native-value="item.id"
            >
              {{ item.name }}
            </b-checkbox>
          </b-tooltip>
        </section>
      </b-field>
      <b-field  :label-position="labelPosition" label="Trip类型:" horizontal>
        <section>
          <b-tooltip
            v-for="(item, index) in tripTypeList"
            :key="item.id"
            :label-position="labelPosition" :label="item.desc"
            type="is-info is-light"
            multilined
          >
            <b-checkbox
              size="is-small"
              v-model="tripTypeIdList"
              :native-value="item.id"
            >
              {{ item.name }}
            </b-checkbox>
          </b-tooltip>
        </section>
      </b-field>
      <!-- <b-field
         :label-position="labelPosition" label="Trip 类型:"
        horizontal
        :loading="!tripTypeList.length > 0"
      >
        <b-select
          size="is-small"
          placeholder="选择Trip类型"
          v-model="parentData.tripType"
        >
          <option
            v-for="option in tripTypeList"
            :value="option.id"
            :key="option.id"
          >
            {{ option.name }}
          </option>
        </b-select>
      </b-field> -->

      <b-field  :label-position="labelPosition" label="文章链接:" :type="urlStatus" horizontal required>
        <b-input
          size="is-small"
          type="url"
          :value="sourceVo.url"
          v-model="sourceVo.url"
        >
        </b-input>
      </b-field>
      <b-field  :label-position="labelPosition" label="多媒体链接:" :type="urlStatus" horizontal required v-if="sourceVo">
        <b-input
          size="is-small"
          type="url"
          :value="sourceVo.mediaLink"
          v-model="sourceVo.mediaLink"
        >
        </b-input>
      </b-field>
      <b-field  :label-position="labelPosition" label="多媒体类型:" horizontal>
        <!-- <b-select
          size="is-small"
          placeholder="选择多媒体类型"
          
        > -->
        <!-- <b-radio-button
          v-model="sourceVo.mediaType"
          value="2"
          type="is-success is-light is-outlined"
          size="is-small"
        >
          <b-icon icon="picture"></b-icon>
          <span>图片</span>
        </b-radio-button>

        <b-radio-button
          v-model="parentData.mediaType"
          value="3"
          type="is-success is-light is-outlined"
          size="is-small"
        >
          <b-icon icon="video"></b-icon>
          <span>视频</span>
        </b-radio-button> -->
        <b-radio-button
          size="is-small"
          v-for="(option, index) in mediaTypeList"
          :native-value="option.id"
          :key="index"
          v-model="sourceVo.mediaType"
          type="is-info"
        >
          {{ option.name }}
        </b-radio-button> 
      
      </b-field>

      <b-field  :label-position="labelPosition" label="关键词" horizontal>
        <section>
          <b-tooltip
            :label-position="labelPosition" :label="key.desc"
            multilined
            v-for="(key, index) in keywordList"
            :key="index"
            type="is-info is-light"
          >
            <b-checkbox
              size="is-small"
              v-model="keywordIdList"
              :native-value="key.id"
            >
              {{ key.name }}
            </b-checkbox>
          </b-tooltip>
        </section>
      </b-field>
      <b-field  :label-position="labelPosition" label="标签" horizontal>
        <section>
          <b-tooltip
            :label-position="labelPosition" :label="key.desc"
            multilined
            v-for="(key, index) in tagList"
            :key="index"
            type="is-info is-light"
          >
            <b-checkbox
              size="is-small"
              v-model="tagIdList"
              :native-value="key.id"
            >
              {{ key.name }}
            </b-checkbox>
          </b-tooltip>
        </section>
      </b-field>

      <b-field  :label-position="labelPosition" label="出发国家:" horizontal>
        <b-select
          size="is-small"
          placeholder="选择出发国家"
          v-model="startCity"
        >
          <option
            v-for="(option, index) in countryList"
            :value="option.id"
            :key="index"
          >
            {{ option.name }}
          </option>
        </b-select>
      </b-field>
      <b-field  :label-position="labelPosition" label="目的国家:" horizontal>
        <b-select
          size="is-small"
          placeholder="选择到达国家"
          required
          v-model="destCountry"
        >
          <option
            v-for="(option, index) in countryList"
            :value="option.id"
            :key="index"
          >
            {{ option.name }}
          </option>
        </b-select>
      </b-field>
      <b-field  :label-position="labelPosition"  label="目的城市:" horizontal>
        <b-select
          size="is-small"
          placeholder="选择到达城市"
          v-model="destCity"
        >
          <option
            v-for="(option, index) in cityList"
            :value="option.id"
            :key="index"
          >
            {{ option.name }}
          </option>
        </b-select>
      </b-field>
       <b-field  :label-position="labelPosition" label="包含景点:" horizontal>
        <!-- <b-select size="is-small" placeholder="选择景点" v-model="sceneIdList">
          <option
            v-for="option in sceneList"
            :native-value="option.id"
            :key="option.id"
          >
            {{ option.cnName }}
          </option>
        </b-select> -->
        <section>
          <b-tooltip
            :label-position="labelPosition" :label="key.desc"
            multilined
            v-for="(key, index) in sceneList"
            :key="index"
            type="is-info is-light"
          >
            <b-checkbox
              size="is-small"
              v-model="sceneIdList"
              :native-value="key.id"
            >
              {{ key.cnName }}
            </b-checkbox>
          </b-tooltip>
        </section>
      </b-field>
      <b-field  :label-position="labelPosition" label="酒店信息:" horizontal required>
        <b-input
          size="is-small"
          type="url"
          :value="parentData.hotelInfo"
          v-model="parentData.hotelInfo"
        >
        </b-input>
      </b-field>
      <b-field  :label-position="labelPosition" label="交通方式:" horizontal>
        <b-select
          size="is-small"
          placeholder="选择交通方式"
          required
          v-model="parentData.transportType"
        >
          <option
            v-for="option in transportTypeList"
            :value="option.id"
            :key="option.id"
          >
            {{ option.name }}
          </option>
        </b-select>
      </b-field>

      <b-field  :label-position="labelPosition" label="highlights" horizontal>
        <!-- <b-input
          size="is-small"
          maxlength="5000"
          type="textarea"
          :value="parentData.highlights"
          v-model="parentData.highlights"
        ></b-input> -->
        <div style="margin-bottom: 20px;">
          <quillEditor v-if="quillEditorShow" :editorRef="editorRef" :editorContent="parentData.highlights" @input="handleGetQuillEditorContent"></quillEditor>
        </div>
        
      </b-field>
      <b-field style="margin-top: 30px;"  :label-position="labelPosition" label="旅游月份" horizontal>
        <!-- <b-datepicker
          size="is-small"
          v-model="parentData.tripTime"
          type="month"
          placeholder="旅游月份."
          icon="calendar-today"
          trap-focus
        >
        </b-datepicker> -->
        <b-input
          size="is-small"
          type="url"
          :value="parentData.tripTime"
          v-model="parentData.tripTime"
        >
        </b-input>
      </b-field>
      <b-field  :label-position="labelPosition" label="旅游多久:" horizontal required>
        <b-input
          size="is-small"
          type="url"
          :value="parentData.duration"
          v-model="parentData.duration"
        >
        </b-input>
      </b-field>

      <div class="level">
        <div class="level-left"></div>
        <div class="level-right">
          <div class="level-item">
            <b-button
              type="is-primary is-success"
              size="is-small"
              @click="confirmBtn"
              >确认</b-button
            >
          </div>
          <div class="level-item" v-if="!parentData.id">
            <b-button
              type="is-primary is-success"
              size="is-small"
              @click="confirmBtn"
              >确认并清空</b-button
            >
          </div>
        </div>
      </div>
    </section>

    <!-- {{ parentData }} -->
  </div>
</template>
<script>
import postData from "../../../common/http";
import quillEditor from "./quillEditor.vue";
export default {
  props: {
    parentData: {
      type: Object,
    },
  },
  components: {
    quillEditor
  },
  watch:{
    destCountry(){
      this.destCity = 0
      this.sceneIdList = []
      this.getSceneList();
      this.getCityList();
    },
    destCity(){
      this.getSceneList();
    }

  },
  methods: {
    handleGetQuillEditorContent(content) {
      this.parentData.highlights = content;
    },
    changeCity() {},
    async getList(type) {
      let list = await postData(this.commonTypeListPath, {
        json: {
          typeIdentity: type,
        },
      });
      return list;
    },
    async confirmBtn() {
      if (!this.parentData.id) {
        delete this.parentData.id;
        this.parentData.status = 0;
      }
      // console.log(this.parentData.destCountry,this.parentData,777)
      
      let json = {
        ...this.parentData,
        articleTypeIdList: this.articleTypeIdList,
        keywordIdList: this.keywordIdList,
        tripTypeIdList: this.tripTypeIdList,
        tagIdList: this.tagIdList,
        sceneIdList:this.sceneIdList,
        isHot: this.isHot,
        sourceVo:this.sourceVo,
        startCity:this.startCity,
        destCountry:this.destCountry,
        destCity:this.destCity?this.destCity:""
      };
      let data = await postData(this.saveArticlePath, {
        json: json,
      });
      if (data == null) {
        this.$buefy.toast.open("Done successfully");
      }
    },
    async getCountryList() {
      let list = await postData(this.getCountryListPath, {
        json: {},
      });

      this.countryList = list.sort((a, b) => a.name.localeCompare(b.name));
      // this.getCityList()
    },
    async getCityList() {
      let list = await postData(this.getCountryListPath, {
        json: {parentId:this.destCountry},
      });

      this.cityList = list.sort((a, b) => a.name.localeCompare(b.name));
    },
    async getAllType() {
      // console.log("eee")
      this.getList("articleType").then((data) => {
        if (Array.isArray(data)) this.articleTypeList = data;
      });
      this.getList("tripType").then((data) => {
        if (Array.isArray(data)) this.tripTypeList = data;
      });
      this.getList("tag").then((data) => {
        if (Array.isArray(data)) this.tagList = data;
      });
      this.getList("keyword").then((data) => {
        if (Array.isArray(data)) this.keywordList = data;
      });

      this.getList("transportType").then((data) => {
        if (Array.isArray(data)) this.transportTypeList = data;
      });
    },
    async getSceneList() {
      let data = await postData(this.getSceneListPath, {
        json: {
          cityId:this.destCity || 0,
          countryId:this.destCountry || 0
        },
      });
      if (Array.isArray(data)) this.sceneList = data;
    },
  },
  data() {
    return {
      labelPosition:"on-border",
      articleTypeIdList: [],
      keywordIdList: [],
      tagIdList: [],
      cityIdList: [],
      sceneIdList: [],
      tripTypeIdList: [],
      tagList: [],
      keywordList: [],
      tripList: [],
      articleTypeList: [],
      tripTypeList: [],
      countryList:[],
      cityList: [],
      transportTypeList: [],
      sceneList: [],
      destCountry:0,
      destCity:0,
      isHot: 0,
      
      sourceVo: {
        name: "",
        url: "",
        mediaType: 2,
        mediaLink: "",
        author: "",
      },destCountry:"",
      mediaTypeList: [
        {
          id: 1,
          name: "纯文本",
        },
        {
          id: 2,
          name: "图片",
        },
        { id: 3, name: "视频" },
      ],
      saveArticlePath: "/article/update",
      getCountryListPath: "/city/list",
      getSceneListPath: "/scene/list",
      typeIdentity: "articleType",
      commonTypeListPath: "/commonType/list",
      typeList: ["tripType", "tag", "keyword", "articleType", "transportType"],
      urlStatus: "is",
      startCity:"",
      destCountry:"",
      editorRef: "artQuillEditor",
      quillEditorShow: false
    };
  },
  created() {
  
    this.articleTypeIdList=this.parentData.articleTypeList.length>0?this.parentData.articleTypeList.map(item=>item.id):[]; delete this.parentData.articleTypeList;
    this.tagIdList=this.parentData.tagList.length>0?this.parentData.tagList.map(item=>item.id):[];delete this.parentData.tagList;
    this.keywordIdList=this.parentData.keywordList.length>0?this.parentData.keywordList.map(item=>item.id):[];delete this.parentData.keywordLIst;
    this.sourceVo=this.parentData.source;delete this.parentData.source;
    this.tripTypeIdList=this.parentData.tripTypeList.length>0?this.parentData.tripTypeList.map(item=>item.id):[];delete this.parentData.tripTypeList;
    this.sceneIdList=this.parentData.sceneList.length>0?this.parentData.sceneList.map(item=>item.id):[];delete this.parentData.sceneList;
    this.destCountry=this.parentData.destCountry;delete this.parentData.destCountry;
    this.startCity=this.parentData.startCity;delete this.parentData.startCity;
    this.isHot=this.parentData.isHot;delete this.parentData.isHot;
    this.destCity=this.parentData.destCity>0?this.parentData.destCity:"";
    this.getAllType();
    this.getCountryList();
    // this.getCityList();
    this.getSceneList();
    this.$nextTick(() => {
      this.quillEditorShow = true;
    })
  },
};
</script>

<style lang="scss" scoped>
.articleForm {
  padding-bottom: 100px;
}
.selW{
  width: 120px !important;
}
</style>