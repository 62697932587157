<template>
  <div class="article">
    <section class="level">
      <div class="level-left">
        <div class="level-item">
          文章标题:
          <b-input
            v-model="headline"
            placeholder="文章标题"
            type="search"
            icon="magnify"
            size="is-small"
            @input="handleDoSearch"
          >
          </b-input>
        </div>
        <div class="level-item">
          国家:
          <b-select
            v-model="countryId"
            size="is-small"
            @input="handleDoSearchCountry"
            filterable
            clearable
          >
            <option value="">请选择</option>
            <option
              v-for="option in countryList"
              :value="option.id"
              :key="option.id"
            >
              {{ option.name }}
            </option>
          </b-select>
        </div>
        <div class="level-item">
          城市
          <b-select
            v-model="cityId"
            size="is-small"
            @input="handleDoSearch"
            filterable
            clearable
          >
            <option value="">请选择</option>
            <option
              v-for="option in searchCityList"
              :value="option.id"
              :key="option.id"
            >
              {{ option.name }}
            </option>
          </b-select>
        </div>
        <div class="level-item">
          文章类型:
          <b-select
            v-model="articleTypeId"
            size="is-small"
            @input="handleDoSearch"
          >
            <option value="">请选择</option>
            <option
              v-for="option in articleTypeList"
              :value="option.id"
              :key="option.id"
            >
              {{ option.name }}
            </option>
          </b-select>
        </div>
        <div class="level-item">
          文章内容:
          <b-input
            v-model="highlights"
            placeholder="文章内容"
            type="search"
            icon="magnify"
            size="is-small"
            @input="handleDoSearch"
          >
          </b-input>
        </div>
        <div class="level-item">
          <b-field>
            <b-checkbox v-model="isChooseFlag" @input="handleChooseFlag"
              >无图片</b-checkbox
            >
          </b-field>
        </div>
        <div class="level-item">
          <b-field>
            <b-checkbox
              v-model="isChooseHighlightsFlag"
              @input="handleChooseFlag"
              >无内容</b-checkbox
            >
          </b-field>
        </div>
        <!-- <div class="level-item">
          状态:
          <b-select v-model="status" size="is-small" @input="handleCurrPageFilter">
            <option value="">请选择</option>
            <option
              v-for="option in statusList"
              :value="option.id"
              :key="option.id"
            >
              {{ option.name }}
            </option>
          </b-select>
        </div> -->
      </div>
      <div class="level-right">
        <b-field class="level-item">
          <b-button
            size="is-small"
            type="is-primary"
            icon-left="lead-pencil"
            :disabled="!activeCheckList.length"
            @click="handleAllUpdate"
            style="margin-right: 10px"
            >批量修改</b-button
          >
          <b-button
            size="is-small"
            type="is-warning"
            icon-left="publish"
            :disabled="!activeCheckStatusList.length"
            style="margin-right: 10px"
            @click="handleBatchRelease"
            >批量发布</b-button
          >
          <b-button
            size="is-small"
            type="is-warning"
            icon-left="delete"
            :disabled="!activeCheckList.length"
            style="margin-right: 10px"
            @click="handleBatchDelete"
            >批量删除</b-button
          >
          <b-button
            size="is-small"
            icon-left="plus"
            type="is-success"
            @click="addNew"
            >添加</b-button
          >
        </b-field>
      </div>
    </section>
    <b-table
      size="is-small"
      :stickyHeaders="stickyHeaders"
      :data="list"
      paginated
      :per-page="pageSize"
      :current-page="current"
      :total="listTotal"
      :backendPagination="true"
      hoverable
      @page-change="handlePager"
      :pageInput="true"
      :debounce-search="1000"
      :loading="isLoading"
      narrowed
      :checkable="true"
      :checked-rows="activeCheckList"
      @check-all="handleCheck"
      @check="handleCheck"
    >
      <b-table-column key="id" label="ID" v-slot="props">
        <span>{{ props.row["id"] }}</span>
      </b-table-column>
      <b-table-column
        key="headline"
        label="文章名称"
        v-slot="props"
        width="400"
      >
        <span
          >{{ props.row["headline"] }}
          <b-button
            size="is-small"
            icon-left="lead-pencil"
            :disabled="!props.row['highlights']"
            @click="handleOpenQuillEditor(props.row)"
            type="is-warning"
          >
          </b-button>
        </span>
      </b-table-column>
      <b-table-column
        v-for="column in columns"
        :key="column.field"
        :label="column.title"
        :visible="column.visible"
        v-slot="props"
      >
        <span>{{ props.row[column.field] }}</span>
      </b-table-column>
      <b-table-column
        v-for="column in sourceColumns"
        :key="column.field"
        v-slot="props"
        label="来源"
      >
        <span>{{
          props.row[column.field] && props.row[column.field].name
        }}</span>
        <b-tag
          type="is-link is-light"
          @click="openLink(props.row[column.field])"
          >打开网址</b-tag
        >
      </b-table-column>
      <b-table-column
        v-for="column in columnsType"
        :key="column.field"
        :label="column.title"
        :visible="column.visible"
        v-slot="props"
      >
        <section>
          <b-tooltip
            :label="key.desc || key.cnName"
            v-for="(key, index) in props.row[column.field]"
            :key="index"
            type="is-success is-dark"
            multilined
          >
            <b-tag type="is-link is-light" size="is-small">{{
              key.name || key.enName
            }}</b-tag>
          </b-tooltip>
        </section>
      </b-table-column>
      <b-table-column label="发布时间" v-slot="props">
        <span v-if="props.row.publishTime">{{
          timestampToDateString(props.row.publishTime)
        }}</span>
      </b-table-column>
      <b-table-column label="状态" v-slot="props">
        {{
          props.row.status == 0
            ? "草稿"
            : props.row.status == 1
            ? "刚创建"
            : props.row.status == 2
            ? "待发表"
            : props.row.status == 4
            ? "已发表"
            : props.row.status == 5
            ? "已删除"
            : props.row.status
        }}
      </b-table-column>
      <b-table-column label="操作" v-slot="props">
        <div class="controlPanel">
          <b-button
            size="is-small"
            icon-left="lead-pencil"
            @click="edit(props.row)"
            type="is-info"
          >
          </b-button>

          <b-button
            size="is-small"
            icon-left="publish"
            type="is-success"
            @click="publish(props.row)"
          >
          </b-button>

          <b-button
            size="is-small"
            icon-left="delete"
            @click="deleteArticle(props.row)"
            type="is-danger"
          >
          </b-button>
        </div>
      </b-table-column>
    </b-table>

    <b-sidebar
      type="is-light"
      :fullheight="fullheight"
      :fullwidth="fullwidth"
      :overlay="isOverlay"
      :right="right"
      v-model="openDrawer"
      :on-cancel="closeSideBar"
    >
      <articleForm :parentData="dataToForm" v-if="openDrawer"></articleForm>
    </b-sidebar>

    <b-sidebar
      type="is-light"
      :fullheight="fullheight"
      :fullwidth="fullwidth"
      :overlay="isOverlay"
      :right="right"
      v-model="articleTypeSidebar"
      :on-cancel="closeSideBar"
    >
      <section class="box">
        <div class="level title is-6">
          <div class="level-left is-danger">批量修改文章属性</div>
          <div class="left-item"></div>
        </div>
        <b-field label-position="on-border" label="属性类型:" horizontal>
          <b-radio
            v-model="articleTypeAttr"
            size="is-small"
            v-for="(item, index) in batchModifyList"
            :key="index"
            :native-value="item.id"
            @input="handleToggleModity"
          >
            {{ item.name }}</b-radio
          >
        </b-field>

        <!-- 文章标题 -->
        <div v-if="articleTypeAttr == 1">
          <div
            v-for="(item, index) in checkAllActiveCheckList"
            :key="index"
            style="margin-bottom: 10px"
            class="titlePicContainer"
          >
            <b-field
              :label-position="labelPosition"
              label="文章名称:"
              horizontal
            >
              <b-input
                v-model="item.headline"
                size="is-small"
                :value="item.headline"
                required
              ></b-input>
            </b-field>
            <b-field
              :label-position="labelPosition"
              label="图片地址:"
              horizontal
            >
              <b-input
                v-if="item.source"
                v-model="item.source.mediaLink"
                size="is-small"
                :value="item.source.mediaLink"
                required
              ></b-input>
              <b-button @click="handleOpenLink(item)">打开链接</b-button>
            </b-field>
          </div>
        </div>
        <!-- 链接地址 -->
        <div v-if="articleTypeAttr == 7">
          <div
            v-for="(item, index) in checkAllActiveCheckList"
            :key="index"
            style="margin-bottom: 10px"
          >
            <b-field
              :label-position="labelPosition"
              label="图片地址:"
              horizontal
            >
              <b-input
                v-if="item.source"
                v-model="item.source.mediaLink"
                size="is-small"
                :value="item.source.mediaLink"
                required
              ></b-input>
              <b-button @click="handleOpenLink(item)">打开链接</b-button>
            </b-field>
          </div>
        </div>
        <!-- 国家城市 -->
        <div v-if="articleTypeAttr == 2">
          <b-field :label-position="labelPosition" label="目的国家:" horizontal>
            <b-select
              size="is-small"
              placeholder="选择到达国家"
              v-model="destCountry"
              @input="handleChoosedestCountry"
            >
              <option value="">选择到达国家</option>
              <option
                v-for="(option, index) in countryList"
                :value="option.id"
                :key="index"
              >
                {{ option.name }}
              </option>
            </b-select>
          </b-field>
          <b-field :label-position="labelPosition" label="目的城市:" horizontal>
            <b-select
              size="is-small"
              placeholder="选择到达城市"
              v-model="destCity"
            >
              <option value="">选择到达城市</option>
              <option
                v-for="(option, index) in cityList"
                :value="option.id"
                :key="index"
              >
                {{ option.name }}
              </option>
            </b-select>
          </b-field>

          <!-- <b-field  :label-position="labelPosition" label="包含景点:" horizontal>
            <section>
              <b-tooltip
                :label-position="labelPosition" :label="key.desc"
                multilined
                v-for="(key, index) in sceneList"
                :key="index"
                type="is-info is-light"
              >
                <b-checkbox
                  size="is-small"
                  v-model="sceneIdList"
                  :native-value="key.id"
                >
                  {{ key.cnName }}
                </b-checkbox>
              </b-tooltip>
            </section>
          </b-field> -->
        </div>

        <!-- 文章类型 -->
        <b-field
          v-if="articleTypeAttr == 3"
          label-position="on-border"
          label="文章类型:"
          horizontal
        >
          <section>
            <b-tooltip
              v-for="(item, index) in articleTypeList"
              :key="index"
              label-position="on-border"
              :label="item.desc"
              type="is-info is-light"
              multilined
            >
              <b-checkbox
                size="is-small"
                v-model="articleTypeIdList"
                :native-value="item.id"
              >
                {{ item.name }}
              </b-checkbox>
            </b-tooltip>
          </section>
        </b-field>

        <!-- 旅游类型 -->
        <b-field
          v-if="articleTypeAttr == 4"
          label-position="labelPosition"
          label="Trip类型:"
          horizontal
        >
          <section>
            <b-tooltip
              v-for="item in tripTypeList"
              :key="item.id"
              :label-position="labelPosition"
              :label="item.desc"
              type="is-info is-light"
              multilined
            >
              <b-checkbox
                size="is-small"
                v-model="tripTypeIdList"
                :native-value="item.id"
              >
                {{ item.name }}
              </b-checkbox>
            </b-tooltip>
          </section>
        </b-field>

        <!-- 关键词 -->

        <b-field
          v-if="articleTypeAttr == 5"
          :label-position="labelPosition"
          label="关键词"
          horizontal
        >
          <section>
            <b-tooltip
              :label-position="labelPosition"
              :label="key.desc"
              multilined
              v-for="(key, index) in keywordList"
              :key="index"
              type="is-info is-light"
            >
              <b-checkbox
                size="is-small"
                v-model="keywordIdList"
                :native-value="key.id"
              >
                {{ key.name }}
              </b-checkbox>
            </b-tooltip>
          </section>
        </b-field>

        <!-- 是否热门 -->
        <b-field
          v-if="articleTypeAttr == 6"
          :label-position="labelPosition"
          label="推荐上热门:"
          horizontal
        >
          <b-radio v-model="isHot" size="is-small" native-value="1" required
            >是</b-radio
          >
          <b-radio v-model="isHot" size="is-small" native-value="0" required
            >否</b-radio
          >
        </b-field>
        <div class="level">
          <div class="level-left"></div>
          <div class="level-right">
            <div class="level-item">
              <b-button
                type="is-primary is-success"
                size="is-small"
                @click="handleSubmitUpdateArt"
                >确认</b-button
              >
            </div>
          </div>
        </div>
      </section>
    </b-sidebar>

    <b-sidebar
      type="is-light"
      :fullheight="fullheight"
      :fullwidth="fullwidth"
      :overlay="isOverlay"
      :right="right"
      v-model="quillEditorShow"
      :on-cancel="handleCloseQuillEditor"
    >
      <div style="box-sizing: border-box; padding: 50px 20px 0">
        <div class="quillHeader">
          <div class="quillHeaderContent">
            文章名称 ：
            <input
              v-model="quillEditorContent.headline"
              size="is-small"
              required
              style="width: 500px"
            />
          </div>
          <div class="quillHeaderContent">
            文章ID: {{ quillEditorContent.id }} 到达国家:
            {{ quillEditorContent.countryName }} 到达城市:
            {{ quillEditorContent.cityName }}
          </div>
          <div class="quillHeaderContent">
            图片地址：
            {{
              quillEditorContent.source && quillEditorContent.source.mediaLink
            }}
            <b-button
              style="margin-left: 10px"
              @click="handleOpenLink(quillEditorContent)"
              >打开链接</b-button
            >
          </div>
          <div class="quillHeaderContent">
            文章类型:
            <section style="display: flex; align-items: center">
              <b-tooltip
                v-for="(key, index) in quillEditorContent.articleTypeList"
                :label="key.desc || key.cnName"
                :key="index"
                type="is-success is-dark"
                multilined
              >
                <b-tag type="is-link is-light" size="is-small">{{
                  key.name || key.enName
                }}</b-tag>
              </b-tooltip>
            </section>
          </div>
          <div class="quillHeaderContent">
            旅游类型:
            <section style="display: flex; align-items: center">
              <b-tooltip
                v-for="(key, index) in quillEditorContent.tripTypeList"
                :label="key.desc || key.cnName"
                :key="index"
                type="is-success is-dark"
                multilined
              >
                <b-tag type="is-link is-light" size="is-small">{{
                  key.name || key.enName
                }}</b-tag>
              </b-tooltip>
            </section>
          </div>
          <div class="quillHeaderContent">
            关键词:
            <section style="display: flex; align-items: center">
              <b-tooltip
                v-for="(key, index) in quillEditorContent.keywordList"
                :label="key.desc || key.cnName"
                :key="index"
                type="is-success is-dark"
                multilined
              >
                <b-tag type="is-link is-light" size="is-small">{{
                  key.name || key.enName
                }}</b-tag>
              </b-tooltip>
            </section>
          </div>
        </div>
        <quillEditor
          v-if="quillEditorShow"
          :editorRef="editorRef"
          :editorContent="quillEditorContent.highlights"
          @input="handleGetQuillEditorContent"
        ></quillEditor>
        <div class="level" style="margin-top: 50px">
          <div class="level-left"></div>
          <div class="level-right">
            <div class="level-item">
              <b-button
                type="is-primary is-success"
                size="is-small"
                @click="handleUpdateQuillEditor"
                >确认</b-button
              >
            </div>
          </div>
        </div>
      </div>
    </b-sidebar>
  </div>
</template>
<script>
import { throttle } from "lodash";
import articleForm from "./articleForm.vue";
import postData from "../../../common/http";
import quillEditor from "./quillEditor.vue";
export default {
  data() {
    return {
      headline: "",
      current: 1,
      pageSize: 15,
      listTotal: 0,
      cityId: "",
      searchCityList: [],
      countryId: "",
      typeIdentity: "",
      highlights: "",
      openSideBar: false,
      fullwidth: true,
      stickyHeaders: true,
      showAllColumns: false,
      fullheight: true,
      right: true,
      isOverlay: true,
      openDrawer: false,
      statusList: [
        "draft",
        "created",
        "reviewed",
        "scheduled",
        "published",
        "deleted",
      ],
      listPath: "/article/ListByAdmin",
      deleteArticlePath: "/article/delete",
      publishArticlePath: "/article/publish",
      getCountryListPath: "/city/list",
      commonTypeListPath: "/commonType/list",
      saveArticlePath: "/article/update",
      getSceneListPath: "/scene/list",
      list: [],
      columns: [
        // { title: "ID", field: "id" },
        // { title: "文章名称", field: "headline", width: 100 },

        { title: "到达国家", field: "countryName" },
        { title: "到达城市", field: "cityName" },
      ],
      sourceColumns: [{ title: "来源", field: "source" }],
      columnsType: [
        { title: "文章类型", field: "articleTypeList" },
        { title: "旅游类型", field: "tripTypeList" },

        { title: "关键词", field: "keywordList" },

        { title: "标签列表", field: "tagList" },
        { title: "景点列表", field: "sceneList" },
      ],
      columnsInfo: [
        { title: "城市列表", field: "cityList" },

        // { title: "状态", field: "status" },
        { title: "热门", field: "isHot" },
        { title: "交通方式", field: "transportType" },
        { title: "目的城市", field: "destCity" },
        { title: "出发城市", field: "startCity" },
        { title: "酒店信息", field: "hotelInfo" },

        { title: "停留时长", field: "duration" },
        { title: "目的地天气", field: "wearch" },
        { title: "旅游月份", field: "tripTime" },
        { title: "亮点总结", field: "highlights" },
      ],
      isLoading: true,
      countryList: [], // 国家列表
      cityList: [], // 城市列表
      articleTypeList: [], // 文章类型列表
      tripTypeList: [], // 旅游类型列表
      articleTypeId: "", // 文章类型ID
      activeCheckList: [], // 选中文章列表
      articleTypeSidebar: false, // 批量修改文章类型弹窗
      sceneList: [],
      keywordList: [],
      // ---------
      articleTypeIdList: [], // 选中文章类型数组
      isHot: "", // 是否热门
      tripTypeIdList: [], // 选中旅游类型
      destCountry: "",
      destCity: "",
      sceneIdList: [],
      keywordIdList: [],
      // --------
      status: "",
      statusList: [
        {
          id: "0",
          name: "草稿",
        },
        {
          id: "1",
          name: "刚创建",
        },
        {
          id: "2",
          name: "待发表",
        },
        {
          id: "4",
          name: "已发表",
        },
        {
          id: "5",
          name: "已删除",
        },
      ],
      activeCheckStatusList: [],
      allDataList: [],
      batchModifyList: [
        {
          id: "1",
          name: "文章标题",
        },
        {
          id: "7",
          name: "图片地址",
        },
        {
          id: "2",
          name: "国家城市",
        },
        {
          id: "3",
          name: "文章类型",
        },
        {
          id: "4",
          name: "旅游类型",
        },
        {
          id: "5",
          name: "关键字",
        },
        {
          id: "6",
          name: "是否热门",
        },
      ],
      articleTypeAttr: 1,
      labelPosition: "on-border",
      checkAllActiveCheckList: [],
      isChooseFlag: false,
      isChooseHighlightsFlag: false,
      quillEditorShow: false,
      quillEditorContent: {},
      editorRef: "artQuillEditor",
    };
  },
  methods: {
    // 编辑 富文本
    handleUpdateQuillEditor() {
      let dataToForm = this.resetParams(this.quillEditorContent);
      this.handlePosData(dataToForm, 1, 1, false);
    },
    // 获取富文本
    handleGetQuillEditorContent(content) {
      this.quillEditorContent.highlights = content;
    },
    handleOpenQuillEditor(row) {
      this.quillEditorContent = row;
      this.quillEditorShow = true;
    },
    // 关闭富文本
    handleCloseQuillEditor() {
      this.quillEditorShow = false;
      this.quillEditorContent = {};
      this.getList();
    },

    handleChooseFlag() {
      const list = JSON.parse(JSON.stringify(this.allDataList));
      if (this.isChooseFlag && this.isChooseHighlightsFlag) {
        this.list = list.filter(
          (item) => item.source && !item.source.mediaLink && !item.highlights
        );
      } else if (this.isChooseFlag) {
        this.list = list.filter(
          (item) => item.source && !item.source.mediaLink
        );
      } else if (this.isChooseHighlightsFlag) {
        this.list = list.filter((item) => !item.highlights);
      } else {
        this.list = list;
      }
    },
    // 打开链接地址
    handleOpenLink(val) {
      window.open(val.source.url);
    },

    handleToggleModity() {
      if (this.articleTypeAttr + "" === "2") {
        this.destCountry = this.activeCheckList[0].destCountry;
        this.getCityList();
      }
    },

    handleChoosedestCountry() {
      this.getCityList();
      this.destCity = "";
      // this.getSceneList();
    },

    // 当前页筛选过滤
    handleCurrPageFilter() {
      const list = JSON.parse(JSON.stringify(this.allDataList));
      if (this.status != "") {
        this.list = list.filter(
          (item) => item.status + "" === this.status + ""
        );
      } else {
        this.list = list;
      }
    },
    // 批量发布
    handleBatchRelease() {
      const list = JSON.parse(JSON.stringify(this.activeCheckStatusList));
      let currIndex = 0;
      list.forEach((item, index) => {
        currIndex = index + 1;
        this.batchRelease(item, currIndex, list.length);
      });
    },
    // 批量删除
    handleBatchDelete() {
      const list = JSON.parse(JSON.stringify(this.activeCheckList));
      let currIndex = 0;
      list.forEach((item, index) => {
        currIndex = index + 1;
        this.batchDelete(item, currIndex, list.length);
      });
    },
    // 批量修改
    handleSubmitUpdateArt() {
      if (!this.articleTypeAttr) {
        this.$buefy.toast.open({
          message: "请选择文章修改属性",
          type: "is-warning",
        });
        return;
      }
      const list = JSON.parse(JSON.stringify(this.checkAllActiveCheckList));

      let currIndex = 0;
      list.forEach((item, index) => {
        currIndex = index + 1;
        let dataToForm = this.resetParams(item);
        if (this.articleTypeAttr + "" === "2") {
          dataToForm["destCountry"] = this.destCountry;
          dataToForm["destCity"] = this.destCity;
          // dataToForm["sceneIdList"] = this.sceneIdList;
        }

        if (this.articleTypeAttr + "" === "3") {
          dataToForm["articleTypeIdList"] = this.articleTypeIdList;
        }

        if (this.articleTypeAttr + "" === "4") {
          dataToForm["tripTypeIdList"] = this.tripTypeIdList;
        }

        if (this.articleTypeAttr + "" === "5") {
          dataToForm["keywordIdList"] = this.keywordIdList;
        }

        if (this.articleTypeAttr + "" === "6") {
          dataToForm["isHot"] = this.isHot;
        }
        if (!dataToForm.sourceVo) {
          dataToForm.sourceVo = {
            url: "",
            mediaType: "",
            mediaLink: "",
          };
        }
        this.handlePosData(dataToForm, currIndex, list.length, true);
      });
    },
    resetParams(item) {
      let keywordIdList = [];
      if (item.keywordList && item.keywordList.length) {
        keywordIdList = item.keywordList.map((item) => item.id);
      }
      let tripTypeIdList = [];
      if (item.tripTypeList && item.tripTypeList.length) {
        tripTypeIdList = item.tripTypeList.map((item) => item.id);
      }

      let tagIdList = [];
      if (item.tagList && item.tagList.length) {
        tagIdList = item.tagList.map((item) => item.id);
      }
      let sceneIdList = [];
      if (item.sceneList && item.sceneList.length) {
        sceneIdList = item.sceneList.map((item) => item.id);
      }

      let articleTypeIdList = [];
      if (item.articleTypeList && item.articleTypeList.length) {
        articleTypeIdList = item.articleTypeList.map((item) => item.id);
      }

      let dataToForm = {
        id: item.id,
        headline: item.headline,
        status: item.status,
        destCity: item.destCity,
        hotelInfo: item.hotelInfo,
        transportType: item.transportType,
        duration: item.duration,
        weather: item.weather,
        highlights: item.highlights,
        tripTime: item.tripTime,
        articleTypeIdList: articleTypeIdList,
        keywordIdList: keywordIdList,
        tripTypeIdList: tripTypeIdList,
        tagIdList: tagIdList,
        sceneIdList: sceneIdList,
        isHot: item.isHot,
        sourceVo: item.source,
        startCity: item.startCity,
        destCountry: item.destCountry,
      };

      return dataToForm;
    },
    async handlePosData(dataToForm, currIndex, len, isClose) {
      console.log(dataToForm, 666);
      let data = await postData(this.saveArticlePath, {
        json: dataToForm,
      });
      if (data == null) {
        if (currIndex == len) {
          this.$buefy.toast.open({
            message: "Done successfully",
            type: "is-success",
          });
          this.$nextTick(() => {
            if (isClose) {
              this.articleTypeSidebar = false;
              this.quillEditorShow = false;
              this.activeCheckList = [];
              this.getList();
            }
          });
        }
      }
    },
    handleAllUpdate() {
      this.destCountry = "";
      this.destCity = "";
      this.sceneIdList = [];
      this.articleTypeAttr = 1;
      this.articleTypeSidebar = true;
      this.articleTypeIdList = [];
      this.checkAllActiveCheckList = JSON.parse(
        JSON.stringify(this.activeCheckList)
      );
    },
    handleCheck(list) {
      this.activeCheckList = list;
      this.activeCheckStatusList = list.filter(
        (item) => item.status + "" === "0"
      );
    },
    handleDoSearchCountry() {
      this.searchCityList = [];
      this.cityId = "";
      if (this.countryId) {
        this.getSearchCityList();
      }
      this.activeCheckList = [];
      this.current = 1;
      this.getList();
    },
    handleDoSearch() {
      if (!this.throttledSearch) {
        this.throttledSearch = throttle(() => {
          console.log("entered");
          this.activeCheckList = [];
          this.current = 1;
          this.getList();
        }, 1000); // 1000ms 的节流间隔
      }

      // 调用节流函数
      this.throttledSearch();
    },
    handlePager(val) {
      this.activeCheckList = [];
      this.current = val;
      this.getList();
    },
    openLink(item) {
      window.open(item.url, "_blank");
    },
    addNew() {
      this.dataToForm = {
        headline: "",
        status: 0,
        source: {
          name: "",
          url: "",
          mediaType: "",
          mediaLink: "",
          author: "",
        },
        keywordList: [],
        articleTypeList: [],
        tagList: [],
        cityIdList: [],
        sceneList: [],
        tripTypeList: [],
        startCity: "",
        destCity: "",
        hotelInfo: "",
        transportType: "",
        duration: "",
        weather: "",
        highlights: "",
        tripTime: "",
        isHot: 0,
      };
      this.openDrawer = true;
      this.openSideBar = true;
    },
    async publish(item) {
      let data = await postData(this.publishArticlePath, {
        json: { id: item.id },
      });
      if (data == null) {
        this.$buefy.toast.open("success");
      }
    },
    async deleteArticle(item) {
      let data = await postData(this.deleteArticlePath, {
        json: {
          id: item.id,
        },
      });
      if (data == null) {
        this.$buefy.toast.open("success");
        this.getList();
      }
    },
    async getList() {
      this.isLoading = true;
      let params = {
        pageNo: this.current,
        pageSize: this.pageSize,
      };
      if (this.countryId) {
        params.countryId = this.countryId;
      }
      if (this.articleTypeId) {
        params.articleTypeId = this.articleTypeId;
      }
      if (this.headline) {
        params.headline = this.headline;
      }

      if (this.cityId) {
        params.cityId = this.cityId;
      }

      if (this.highlights) {
        params.highlights = this.highlights;
      }
      let data = await postData(this.listPath, {
        json: params,
      });
      if (data) {
        this.list = data.records;
        if (this.isChooseFlag) {
          this.list = data.records.filter(
            (item) => item.source && !item.source.mediaLink
          );
        }
        this.isLoading = false;
        this.listTotal = data.total;
        this.allDataList = data.records;
      }
    },
    toggleColumns() {
      this.showAllColumns != this.showAllColumns;
    },
    edit(item) {
      for (var key in item) {
        if (item[key] === null) {
          item[key] = [];
        }
      }
      this.dataToForm = { ...item };

      this.openDrawer = true;
      this.openSideBar = true;
    },
    closeSideBar() {
      this.openSideBar = false;
      this.articleTypeSidebar = false;
      this.activeCheckList = [];
      this.activeCheckStatusList = [];
      this.getList();
    },

    async getCountryList() {
      let list = await postData(this.getCountryListPath, {
        json: {},
      });

      this.countryList = list.sort((a, b) => a.name.localeCompare(b.name));
    },
    async getAllType() {
      this.getTypeList("articleType").then((data) => {
        if (Array.isArray(data)) this.articleTypeList = data;
      });
      this.getTypeList("tripType").then((data) => {
        if (Array.isArray(data)) this.tripTypeList = data;
      });
      this.getTypeList("keyword").then((data) => {
        if (Array.isArray(data)) this.keywordList = data;
      });
    },
    async getTypeList(type) {
      let list = await postData(this.commonTypeListPath, {
        json: {
          typeIdentity: type,
        },
      });
      return list;
    },

    // 批量发布
    async batchRelease(item, currentIndex, len) {
      let data = await postData(this.publishArticlePath, {
        json: { id: item.id },
      });
      if (data == null && currentIndex === len) {
        this.$buefy.toast.open("success");
        this.activeCheckList = [];
        this.activeCheckStatusList = [];
        this.getList();
      }
    },

    // 批量删除
    async batchDelete(item, currentIndex, len) {
      let data = await postData(this.deleteArticlePath, {
        json: { id: item.id },
      });
      if (data == null && currentIndex === len) {
        this.$buefy.toast.open("success");
        this.activeCheckList = [];
        this.activeCheckStatusList = [];
        this.getList();
      }
    },

    // 获取城市
    async getCityList() {
      let list = await postData(this.getCountryListPath, {
        json: { parentId: this.destCountry },
      });

      this.cityList = list.sort((a, b) => a.name.localeCompare(b.name));
    },
    async getSearchCityList() {
      let list = await postData(this.getCountryListPath, {
        json: { parentId: this.countryId },
      });

      this.searchCityList = list.sort((a, b) => a.name.localeCompare(b.name));
    },
    async getSceneList() {
      let data = await postData(this.getSceneListPath, {
        json: { cityId: this.destCountry || 0 },
      });
      if (Array.isArray(data)) this.sceneList = data;
    },
    timestampToDateString(timestamp, format) {
      var date = new Date();
      if (timestamp) {
        date = new Date(timestamp);
      }
      var year = date.getFullYear(); // 获取年份
      var month = (date.getMonth() + 1).toString().padStart(2, "0"); // 获取月份并补零
      var day = date.getDate().toString().padStart(2, "0"); // 获取日期并补零
      switch (format) {
        case "yyyy-mm-dd":
          return `${year.toString()}-${month}-${day}`;
        case "yyyy/mm/dd":
          return `${year.toString()}/${month}/${day}`;
        case "yyyy年mm月dd日":
          return `${year.toString()}年${month}月${day}日`;
        case "mm-dd":
          return `${month}-${day}`;
        default:
          return `${year.toString()}-${month}-${day}`;
      }
    },
  },
  created() {
    this.articleTypeAttr = 1;
    this.getCountryList();
    this.getList();
    this.getAllType();
  },
  watch: {
    showAllColumns(o, n) {
      let longColumns = [];
    },
  },
  components: { articleForm, quillEditor },
};
</script>

<style lang="scss" scoped>
.titlePicContainer {
  display: flex;
  justify-content: space-between;
  ::v-deep .field {
    width: 50%;
  }
  ::v-deep .field-label {
    min-width: 100px;
  }
}
.article {
  width: 100%;
  overflow: hidden;
  overflow-x: scroll;
}
.sidebar-content {
  min-width: 500px !important;
}
.controlPanel {
  display: flex;
  justify-content: space-between;
}

.quillHeader {
  width: 100%;

  .quillHeaderContent {
    margin-bottom: 10px;

    width: 100%;
    display: flex;
    align-items: center;
  }
}
</style>


