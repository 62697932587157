let destinationsList = [
  {
    type: "travel-cities",
    name: "travelCities",
    path: "travel-cities",
    title: "travel cities",
    keywordId: "419",
    keywordName: "Cities"
  },
  {
    type: "travel-landmarks",
    name: "travelLandmarks",
    path: "travel-landmarks",
    title: "travel landmarks",
    keywordId: "357",
    keywordName: "Landmarks"
  },

  {
    type: "travel-attractions",
    name: "travelAttractions",
    path: "travel-attractions",
    title: "travel attractions",
    keywordId: "418",
    keywordName: "attractions"
  },
  {
    type: "travel-landmarks-monuments",
    name: "travelLandmarksMonuments",
    path: "travel-landmarks-monuments",
    title: "travel landmarks monuments",
    keywordId: "418",
    keywordName: "Landmarks and Monuments"
  },
  {
    type: "travel-nustling-markets",
    name: "travelNustlingMarkets",
    path: "travel-nustling-markets",
    title: "travel nustling markets",
    keywordId: "418",
    keywordName: "Nustling Markets"
  }, 

  {
    type: "travel-peaceful-parks",
    name: "travelPeacefulParks",
    path: "travel-peaceful-parks",
    title: "travel peaceful parks",
    keywordId: "418",
    keywordName: "Peaceful Parks"
  },
  {
    type: "travel-must-see-sights",
    name: "travelMustSeeSights",
    path: "travel-must-see-sights",
    title: "travel must-see sights",
    keywordId: "418",
    keywordName: "must-see sights"
  }

]

let destinationsRouteList = destinationsList.map(item => {
  return {
      path: `/${item.path}/:name?`,
      name: item.name,
      component: () => import(`@/views/thingToDo/country/basics.vue`),
      meta: {
          tab: "Hot",
          title: item.title,
          type: item.type,
          keywordId: item.keywordId,
          keywordName: item.keywordName
      }
  }
})

export default destinationsRouteList