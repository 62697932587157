//国家路由类型路由配置
let travelTypeList = [
    {
        type: "food",
        name: "attractionsTravelFood",
        path: "travel-foods",
        title: "Attractions Travel Food",
        articleTypeId: "287",
        articleTypeName: "Foods"
    },
    {
        type: "cultural",
        name: "attractionsTravelCultural",
        path: "travel-cultural",
        title: "Attractions Travel Cultural",
        articleTypeId: "282",
        articleTypeName: "Cultural"
    },
    {
        type: "nature",
        name: "attractionsTravelNature",
        path: "travel-nature",
        title: "Attractions Travel Nature",
        articleTypeId: "417",
        articleTypeName: "Nature"
    },
    {
        type: "activities",
        name: "attractionsTravelActivities",
        path: "travel-activities",
        title: "Attractions Travel Activities",
        articleTypeId: "300",
        articleTypeName: "Activities"
    },
    {
        type: "luxury",
        name: "attractionsTravelLuxury",
        path: "travel-luxury",
        title: "Attractions Travel Luxury",
        articleTypeId: "43",
        articleTypeName: "Luxury"
    },
    {
        type: "family",
        name: "attractionsTravelFamily",
        path: "travel-family",
        title: "Attractions Travel Family",
        articleTypeId: "45",
        articleTypeName: "Family"
    },
]

let routes = [
    //things to do页面配置
    {
        path: "/things-to-do/:countryName/:attractionName",
        name: "attractionsThingsToDo",
        component: () => import('@/views/attraction/thingsToDo/index.vue'),
        meta: {
            tab: "Hot",
            title: "Attractions Things To Do"
        }
    },

    //景点新闻页面
    {
        path: "/travel-attractions-news/:countryName/:attractionName",
        name: "attractionNews",
        component: () => import("@/views/attraction/news/index.vue"),
        meta: {
            tab: "News",
            title: "Travel Attractions News"
        }
    },

    //景点faq页面
    {
        path: "/travel-attractions-faq/:countryName/:attractionName/:page?",
        name: "attractionFaq",
        component: () => import("@/views/attraction/faq/index.vue"),
        meta: {
            tab: "FAQ",
            title: "Travel Attractions Faq"
        }
    },

    //景点faq详情页面
    {
        path: "/travel-faq/:countryName/:attractionName/:headline",
        name: "TravelFaqDetail",
        component: () => import("@/views/faq/faqDetail.vue"),
        meta: {
            tab: "FAQ",
            title: "travel Faq experience"
        }
    },
]

travelTypeList.forEach(item => {
    routes.push({
        path: `/${item.path}/:countryName/:attractionName`,
        name: item.name,
        component: () => import(`@/views/attraction/thingsToDo/types/index.vue`),
        meta: {
            tab: "Hot",
            title: item.title,
            type: item.type,
            articleTypeId: item.articleTypeId,
            articleTypeName: item.articleTypeName
        }
    })
})



export default routes