//国家路由类型路由配置
let travelTypeList = [
    {
        type: "food",
        name: "travelFood",
        path: "travel-foods",
        title: "Travel Food",
        articleTypeId: "287",
        articleTypeName: "Foods"
    },
    {
        type: "cultural",
        name: "travelCultural",
        path: "travel-cultural",
        title: "Travel Cultural",
        articleTypeId: "282",
        articleTypeName: "Cultural"
    },
    {
        type: "nature",
        name: "travelNature",
        path: "travel-nature",
        title: "Travel Nature",
        articleTypeId: "417",
        articleTypeName: "Nature"
    },
    {
        type: "activities",
        name: "travelActivities",
        path: "travel-activities",
        title: "Travel Activities",
        articleTypeId: "300",
        articleTypeName: "Activities"
    },
    {
        type: "luxury",
        name: "travelLuxury",
        path: "travel-luxury",
        title: "Travel Luxury",
        articleTypeId: "43",
        articleTypeName: "Luxury"
    },
    {
        type: "family",
        name: "travelFamily",
        path: "travel-family",
        title: "Travel Family",
        articleTypeId: "45",
        articleTypeName: "Family"
    },
    {
        type: "destinations",
        name: "travelDestinations",
        path: "travel-destinations",
        title: "Travel Destinations",
        articleTypeId: "55",
        articleTypeName: "Destinations"
    }
]
let travelTypeRouteList = travelTypeList.map(item => {
    return {
        path: `/${item.path}/:name?`,
        name: item.name,
        component: () => import(`@/views/thingToDo/country/index.vue`),
        meta: {
            tab: "Hot",
            title: item.title,
            type: item.type,
            articleTypeId: item.articleTypeId,
            articleTypeName: item.articleTypeName
        }
    }
})

export default travelTypeRouteList