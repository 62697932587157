<template>
    <div class="searchResult">
        <div class="searchResult-title">
            <span>100000+</span>
            <span> search items</span>
            <span> ordered default by popularity</span>
        </div>
        <!-- <ul class="mainContentNewsList" v-infinite-scroll="loadMore" :infinite-scroll-disabled="dataLoadStop"
            :infinite-scroll-distance="50">
            <ArticleItem class="articleItem" v-for="(item, index) in result" :key="index" :info="item" width="16%"
                margin="15px 0 0"></ArticleItem>
        </ul> -->
        <ul class="mainContentNewsList">
            <ArticleItem class="articleItem" v-for="(item, index) in result" :key="index" :info="item" width="16%"
                margin="15px 0 0"></ArticleItem>
        </ul>

        <div class="pagination" v-if="total">
            <!-- <b-pagination :total="total" v-model="pageNo" :range-before="rangeBefore" :range-after="rangeAfter"
                :order="order" :size="size" :simple="isSimple" :rounded="isRounded" :per-page="pageSize"
                :icon-prev="prevIcon" :icon-next="nextIcon" aria-next-label="Next page"
                aria-previous-label="Previous page" aria-page-label="Page" aria-current-label="Current page"
                :page-input="hasInput" :page-input-position="inputPosition" :debounce-page-input="inputDebounce"
                @change="paginationChange" /> -->
            <b-pagination :total="total" v-model="pageNo" :per-page="pageSize" :range-before="rangeBefore"
                :range-after="rangeAfter" :order="order" :size="size" :simple="isSimple" :rounded="isRounded"
                :icon-prev="prevIcon" :icon-next="nextIcon" aria-next-label="Next page"
                aria-previous-label="Previous page" aria-page-label="Page" aria-current-label="Current page"
                :page-input="hasInput" :page-input-position="inputPosition" :debounce-page-input="inputDebounce"
                @change="paginationChange">

                <template #default="props">
                    <b-pagination-button :page="props.page" :id="`page${props.page.number}`"
                        :tag="props.page.disabled ? 'a' : 'router-link'" :to="handleLink(props.page.number)"
                        target="_blank">
                        {{ convertToRoman(props.page.number) }}
                    </b-pagination-button>
                </template>
            </b-pagination>
        </div>
    </div>
</template>

<script>
import ArticleItem from '@/components/articleItem.vue'
import { getArticleList } from '@/api/discover'
export default {
    components: {
        ArticleItem
    },
    computed: {
        state() {
            return this.$store.state.discover.state
        }
    },
    watch: {
        state: {
            handler: function (nv, ov) {
                // this.dataLoadStop = false
                this.result = []
                this.pageNo = 1
                this.getArticleList()
            },
            deep: true,
            // immediate: true
        },
        // "$route"(to, from) {
        //     if (this.$route.params.page) {
        //         this.pageNo = Number(this.$route.params.page)
        //     } else {
        //         this.pageNo = 1
        //     }
        //     this.getArticleList()
        // },
    },
    data() {
        return {
            result: [],
            pageNo: 1,
            pageSize: 120,
            total: 0,
            // dataLoadStop: false,

            initPage: 1,
            hasPage: false,

            rangeBefore: 3,
            rangeAfter: 2,
            order: 'is-centered',
            size: 'is-medium',
            isSimple: false,
            isRounded: false,
            hasInput: false,
            prevIcon: 'chevron-left',
            nextIcon: 'chevron-right',
            inputPosition: '',
            inputDebounce: ''
        }
    },
    methods: {
        // async getArticleList() {
        //     this.dataLoadStop = true
        //     let result = await getArticleList({
        //         countryId: this.state.country,
        //         cityId: this.state.city,
        //         tripTypeId: this.state.triptype,
        //         articleTypeId: this.state.articleTypeId,
        //         pageNo: this.pageNo,
        //         pageSize: this.pageSize,
        //     })
        //     this.total = result.total
        //     this.totalPages = Math.ceil(this.total / this.pageSize); // 总页数
        //     this.result = this.result.concat(this.supplement(result.records))
        //     if (this.result.length >= this.total || !result.records.length) {
        //         this.dataLoadStop = true
        //     } else {
        //         this.dataLoadStop = false
        //     }
        // },
        // loadMore() {
        //     if (this.dataLoadStop) {
        //         return
        //     }
        //     this.pageNo += 1
        //     this.getArticleList()
        // },

        async getArticleList() {
            let result = await getArticleList({
                countryId: this.state.country,
                cityId: this.state.city,
                tripTypeId: this.state.triptype,
                articleTypeId: this.state.articleTypeId,
                pageNo: this.pageNo,
                pageSize: this.pageSize,
            })
            this.total = result.total
            this.totalPages = Math.ceil(this.total / this.pageSize); // 总页数
            this.result = this.supplement(result.records)
        },

        //补充空数据
        supplement(arr) {
            if (this.pageNo === this.totalPages) {
                let num = 6 - arr.length % 6
                if (num) {
                    for (let i = 0; i < num; i++) {
                        arr.push({})
                    }
                }
            }
            return arr
        },

        paginationChange() {
            if (this.pageNo !== this.initPage) {
                let path = ''
                if (this.hasPage) {
                    let arr = window.location.pathname.split('/');
                    arr.splice(arr.length - 1, 1, this.pageNo);
                    path = `${arr.join('/')}${window.location.search}`
                } else {
                    path = `${window.location.pathname}/${this.pageNo}${window.location.search}`
                }
                window.open(path)
            }
            this.pageNo = this.initPage
        },

        handleLink(num) {
            if (num !== this.initPage) {
                let path = ''
                if (this.hasPage) {
                    let arr = window.location.pathname.split('/');
                    arr.splice(arr.length - 1, 1, num);
                    path = `${arr.join('/')}${window.location.search}`
                } else {
                    path = `${window.location.pathname}/${num}${window.location.search}`
                }
                return path
            } else {
                return ''
            }
        },

        convertToRoman(num) {
            return num
        }
    },
    created() {
        let nameParams = Object.values(this.$route.params).filter(item => {
            if (item) {
                return item
            }
        })
        let isPage = !isNaN(Number(nameParams[nameParams.length - 1]))

        if (isPage) {
            this.pageNo = Number(nameParams[nameParams.length - 1])
            this.hasPage = true
        } else {
            this.pageNo = 1
        }

        this.initPage = this.pageNo
        this.getArticleList()
    }
}
</script>
<style lang="scss" scoped>
/* @import url(); 引入css类 */
.searchResult {
    // padding: 0 20px 20px;
    border-radius: 4px;
    margin-top: 20px;
    // padding-left: 40px;

    .searchResult-title {
        span {
            font-size: 14px;

            &:nth-child(1) {
                color: red;
            }

            &:nth-child(2) {
                color: #000;
            }

            &:nth-child(3) {
                color: gray;
                margin-left: 10px;
            }
        }
    }

    .mainContentNewsList {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        min-height: 530px;
    }


    .pagination {
        display: flex;
        justify-content: center;
        margin-top: 20px;

        :deep(.is-current) {
            background: #f6ce4f;
            border-color: #f6ce4f;
        }
    }
}
</style>