let familyList = [
  {
    type: "travel-family-friendly-destinations",
    name: "travelFamilyFriendlyDestinations",
    path: "travel-family-friendly-destinations",
    title: "travel family-friendly destinations",
    keywordId: "419",
    keywordName: "family-friendly destinations"
  },
  {
    type: "travel-family-friendly-activities",
    name: "travelFamilyFriendlyActivities",
    path: "travel-family-friendly-activities",
    title: "travel family-friendly-activities",
    keywordId: "357",
    keywordName: "family-friendly-activities"
  },

  {
    type: "travel-attractions",
    name: "travelAttractions",
    path: "travel-attractions",
    title: "travel attractions",
    keywordId: "418",
    keywordName: "attractions"
  },
  {
    type: "travel-amusement-parks",
    name: "travelAmusementParks",
    path: "travel-amusement-parks",
    title: "travel amusement parks",
    keywordId: "418",
    keywordName: "amusement parks"
  },
  {
    type: "travel-interactive-museums",
    name: "travelInteractiveMuseums",
    path: "travel-interactive-museums",
    title: "travel interactive museums",
    keywordId: "418",
    keywordName: "interactive museums"
  }, 

  {
    type: "travel-kid-friendly-beaches",
    name: "travelkidFriendlyBeaches",
    path: "travel-kid-friendly-beaches",
    title: "travel kid-friendly beaches",
    keywordId: "418",
    keywordName: "kid-friendly beaches"
  },
  {
    type: "travel-nature-reserves",
    name: "travelNatureReserves",
    path: "travel-nature-reserves",
    title: "travel nature reserves",
    keywordId: "418",
    keywordName: "nature reserves"
  },
  {
    type: "travel-zoos",
    name: "travelzoos",
    path: "travel-zoos",
    title: "travel zoos",
    keywordId: "418",
    keywordName: "zoos"
  },

  {
    type: "travel-family-friendly-resorts",
    name: "travelFamilyFriendlyResorts",
    path: "travel-family-friendly-resorts",
    title: "travel family-friendly resorts",
    keywordId: "418",
    keywordName: "family-friendly resorts"
  },

  {
    type: "travel-scenic-family-hiking-trails",
    name: "travelScenicFamilyHikingTrails",
    path: "travel-scenic-family-hiking-trails",
    title: "travel scenic family hiking trails",
    keywordId: "418",
    keywordName: "scenic family hiking trails"
  },
  {
    type: "travel-outdoor-adventures",
    name: "travelOutdoorAdventures",
    path: "travel-outdoor-adventures",
    title: "travel outdoor adventures",
    keywordId: "418",
    keywordName: "outdoor adventures"
  },
  {
    type: "travel-cultural-experiences",
    name: "travelCulturalExperiences",
    path: "travel-cultural-experiences",
    title: "travel cultural experiences",
    keywordId: "418",
    keywordName: "cultural experiences"
  },

  {
    type: "travel-water-parks",
    name: "travelWaterParks",
    path: "travel-water-parks",
    title: "travel water parks",
    keywordId: "418",
    keywordName: "water parks"
  },

  {
    type: "travel-historical-sites",
    name: "travelHistoricalSites",
    path: "travel-historical-sites",
    title: "travel historical sites",
    keywordId: "418",
    keywordName: "historical sites"
  },

  {
    type: "travel-farm-visits",
    name: "travelFarmVisits",
    path: "travel-farm-visits",
    title: "travel farm visits",
    keywordId: "418",
    keywordName: "farm visits"
  },
  {
    type: "travel-safaris",
    name: "travelSafaris",
    path: "travel-safaris",
    title: "travel safaris",
    keywordId: "418",
    keywordName: "safaris"
  },
  {
    type: "travel-eco-tours",
    name: "travelEcoTours",
    path: "travel-eco-tours",
    title: "travel eco-tours",
    keywordId: "418",
    keywordName: "eco-tours"
  }
]

let familyRouteList = familyList.map(item => {
  return {
      path: `/${item.path}/:name?`,
      name: item.name,
      component: () => import(`@/views/thingToDo/country/family.vue`),
      meta: {
          tab: "Hot",
          title: item.title,
          type: item.type,
          keywordId: item.keywordId,
          keywordName: item.keywordName
      }
  }
})

export default familyRouteList