<template>
  <div class="hotFood">
    <div class="title">Enrich Life with Various Taste</div>
    <b-carousel v-model="active" :autoplay="true" :interval="4000" :pause-info="false" :arrow="false" :indicator="false"
      :repeat="false">
      <b-carousel-item v-for="(group, i) in groups" :key="i" style="height: 100%">
        <section class="carousel-item-box">
          <div class="first">
            <b-image :src="group.first.img" ratio="1by1" @click="firstfFoodLink(group)"></b-image>
            <div class="firstTitleContainer">
              <p class="country title is-1" @click="firstfFoodLink(group)">{{ group.countryName }} Food</p>
              <p class="headline title is-3" v-if="group.first" @click="firstfFoodLink(group)">
                {{ group.first.headline }}
              </p>
            </div>
          </div>
          <div class="list columns is-multiline">
            <a :href="openHotFood(item)" target="_blank" v-for="item in group.list" :key="item.id"
              class="column is-half">
              <div class="article-item ">
                <img class="left" :src="item.source.mediaLink" @error="onImageError" />
                <div class="right">
                  <p>{{ item.headline }}</p>
                </div>
              </div>
            </a>
          </div>
        </section>
      </b-carousel-item>
    </b-carousel>

    <div class="btns">
      <img @click="subtract" src="@/assets/img/left-btn.png" :class="{ opacity: active === 0 }" alt="" />
      <img @click="add" src="@/assets/img/right-btn.png" :class="{ opacity: active === groups.length - 1 }" alt="" />
    </div>
  </div>
</template>

<script>
import { getArticleList } from "@/api/discover";
import OpenLinkMixins from "@/mixins/openLink";
import { replaceSpacesWithDash } from "@/utils/utils";
export default {
  mixins: [OpenLinkMixins],
  data() {
    return {
      active: 0,
      groups: [],
      hotCountries: [
        {
          name: "China",
          id: "285",
        },
        {
          id: "18",
          name: "Indonesia",
        },
        {
          id: "305",
          name: "Japan",
        },
        {
          id: "15",
          name: "Korea",
        },
        {
          id: "14",
          name: "Thailand",
        },
        {
          id: "16",
          name: "Vietnam",
        },
        {
          id: "17",
          name: "Malaysia",
        },
        {
          id: "22",
          name: "Philippines",
        },
        {
          id: "20",
          name: "Singapore",
        },
        {
          id: "316",
          name: "Turkey",
        }
      ],
    };
  },
  methods: {
    async getGroups() {
      for (let i = 0; i < this.hotCountries.length; i++) {
        let result = await getArticleList({
          countryId: this.hotCountries[i].id,
          articleTypeId: "424",
          //   articleTypeId: "332",
          pageNo: 1,
          pageSize: 9,
        });

        let groupItem = {
          countryId: this.hotCountries[i].id,
          countryName: this.hotCountries[i].name,
        };
        if (result.records.length) {
          let img = "";
          if (result.records[0] && result.records[0].source) {
            img = result.records[0].source.mediaLink;
          }
          groupItem.first = {
            img: img,
            headline: result.records[0].headline,
            publishTime: result.records[0].publishTime,
            id: result.records[0].id,
          };
          groupItem.list = result.records.slice(1, result.records.length);
        }
        this.groups.push(groupItem);
      }
    },
    subtract() {
      if (this.active !== 0) {
        this.active = this.active - 1;
      }
    },
    add() {
      if (this.active !== this.groups.length - 1) {
        this.active = this.active + 1;
      }
    },
    onImageError(event) {
      event.target.src = require("@/assets/img/default.png");
    },
    firstfFoodLink(group) {
      let path = `/hot-food/${replaceSpacesWithDash(
        group.countryName
      )}/${replaceSpacesWithDash(group.first.headline)}`;
      let query = {
        id: group.first.id,
        img: encodeURIComponent(group.first.mediaLink),
      };
      let routeData = this.$router.resolve({
        path: path, // path 要跳转的路由地址
        query: query,
      });
      window.open(routeData.href);
    },
  },
  created() {
    this.getGroups();
  },
  mounted() { },
};
</script>
<style lang="scss" scoped>
.title {
  margin-top: 20px;
}

.carousel {
  height: 600px;

  .carousel-items {
    .carousel-item-box {
      height: 600px;
      display: flex;
      justify-content: space-between;

      .first {
        width: 45%;
        border-radius: 12px;
        overflow: hidden;
        position: relative;

        .firstTitleContainer {

          background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0.1), transparent);
          background-size: cover;
          position: absolute;
          bottom: 8rem;
          left: 3rem;
          min-width: 30%;
          max-width: 80%;
          // width:8rem;
          border-radius: .5rem;
          padding: .5rem;

          .headline {
            color: #fff;
            cursor: pointer;
            border-radius: 6px;
          }

          .headline:hover {
            text-decoration: underline;
          }

          .country {
            color: #fff;
            z-index: 10;
            cursor: pointer;
          }

          .country:hover {
            text-decoration: underline;
          }
        }

      }

      .list {
        width: 55%;
        padding-right: 6px;
        padding-bottom: 4px;

        a {
          height: 23%;
          display: block;
          margin-bottom: 2%;

          .article-item {
            height: 100%;
            display: flex;
            align-items: center;
            background: #fff;
            border: 1px solid rgb(224 238 250);
            overflow: hidden;
            padding: 1rem;
            border-radius: 12px;
            cursor: pointer;

            .left {
              width: 100px;
              height: 100px;
              border-radius: 12px;
              object-fit: cover;
            }

            .right {
              flex: 1;
              margin-left: 2rem;
              font-size: 16px;
            }
          }

          .article-item:hover {
            box-shadow: 0 2px 8px rgba(100, 100, 100, 0.2);
          }
        }
      }
    }
  }
}

.btns {
  display: flex;
  justify-content: flex-end;

  img {
    width: 40px;
    margin: 1rem;
    cursor: pointer;
  }

  .opacity {
    opacity: 0.5;
  }
}
</style>