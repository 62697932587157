
import ky from 'ky'
const base =  "https://www.leisuretripinasia.com/crm";
import { ToastProgrammatic as Toast } from 'buefy'
var postData = async (path, config) => {
   let token = localStorage.getItem("crm-token");

   let data = await ky.post(base + path, {
      headers: {
         "crm-token": token
      },
      ...config
   }).json();
   // console.log(data, 7777)
   // if (data.retCode != 200) {
   //    Toast.open({
   //       duration: 1000,
   //       positon: "is-top",
   //       message: "需要重新登录",
   //       type: "is-danger"
   //    });
   //   window.location.href="/login"
   // } else {
   //    return data.data || data.retCode;
   // }
   // console.log(data,33)
   if(data.msg!='success'){
     
      return data.msg
   }else{
      return data.data
   }
  

};
export default postData;