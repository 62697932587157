let cultureList = [
  {
    type: "travel-traditional-clothing",
    name: "travelTraditionalClothing",
    path: "travel-traditional-clothing",
    title: "travel traditional clothing",
    keywordId: "428",
    keywordName: "Traditional Clothing"
  },
  {
    type: "travel-literature",
    name: "travelliterature",
    path: "travel-literature",
    title: "travel literature",
    keywordId: "430",
    keywordName: "literature"
  },

  {
    type: "travel-cuisine",
    name: "travelCuisine",
    path: "travel-cuisine",
    title: "travel cuisine",
    keywordId: "428",
    keywordName: "cuisine"
  },

  {
    type: "travel-architecture",
    name: "travelArchitecture",
    path: "travel-architecture",
    title: "travel architecture",
    keywordId: "428",
    keywordName: "architecture"
  },

  {
    type: "travel-institutional-culture",
    name: "travelInstitutionalCulture",
    path: "travel-institutional-culture",
    title: "travel institutional culture",
    keywordId: "428",
    keywordName: "Institutional Culture"
  },

  {
    type: "travel-local-customs",
    name: "travelLocalCustoms",
    path: "travel-local-customs",
    title: "travel local customs",
    keywordId: "428",
    keywordName: "Local Customs"
  },

  {
    type: "travel-laws",
    name: "travelLaws",
    path: "travel-laws",
    title: "travel laws",
    keywordId: "428",
    keywordName: "Laws"
  },

  {
    type: "travel-education",
    name: "travelEducation",
    path: "travel-education",
    title: "travel education",
    keywordId: "428",
    keywordName: "Education"
  },
  {
    type: "travel-behavioral-culture",
    name: "travelBehavioralCulture",
    path: "travel-behavioral-culture",
    title: "travel behavioral culture",
    keywordId: "428",
    keywordName: "Behavioral Culture"
  },
  {
    type: "travel-festivals",
    name: "travelfestivals",
    path: "travel-festivals",
    title: "travel festivals",
    keywordId: "428",
    keywordName: "Festivals"
  },
  {
    type: "travel-clothing",
    name: "travelclothing",
    path: "travel-clothing",
    title: "travel clothing",
    keywordId: "428",
    keywordName: "Clothing"
  },
  {
    type: "travel-rituals",
    name: "travelRituals",
    path: "travel-rituals",
    title: "travel rituals",
    keywordId: "428",
    keywordName: "Rituals"
  },
  {
    type: "travel-spiritual-culture",
    name: "travelSpiritualCulture",
    path: "travel-spiritual-culture",
    title: "travel spiritual culture",
    keywordId: "428",
    keywordName: "Spiritual Culture"
  },

  {
    type: "travel-philosophy",
    name: "travelPhilosophy",
    path: "travel-philosophy",
    title: "travel philosophy",
    keywordId: "428",
    keywordName: "Philosophy"
  },
  {
    type: "travel-religion",
    name: "travelReligion",
    path: "travel-religion",
    title: "travel religion",
    keywordId: "428",
    keywordName: "Religion"
  },
  {
    type: "travel-art",
    name: "travelArt",
    path: "travel-art",
    title: "travel art",
    keywordId: "428",
    keywordName: "Art"
  }
]

let cultureRouteList = cultureList.map(item => {
  return {
    path: `/${item.path}/:name?`,
    name: item.name,
    component: () => import(`@/views/thingToDo/country/culture.vue`),
    meta: {
      tab: "Hot",
      title: item.title,
      type: item.type,
      keywordId: item.keywordId,
      keywordName: item.keywordName
    }
  }
})

export default cultureRouteList